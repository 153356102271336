import React, { Component } from 'react';

import './index.css';
class CapacitacaoPortoFeliz extends Component {

  render() {
    return (
      <div>
        <div className="capacitacao-intro">
          <div className='page-title'>Fotos da Capacitação junto aos Coordenadores e Diretores de Porto Feliz</div>

          <div className="atv-text">Livros paradidáticos “Encanto dos Rios” organizados para serem entregues às escolas municipais de Porto Feliz-SP. </div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/1.jpg" alt="Foto 1" className="atv-img cap-img"></img>

          <div className="atv-text">A escritora Samara Rached apresentando o conteúdo científico do livro para a gestão das escolas de Porto Feliz. </div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/2.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">A escritora Angélica Felício apresentando o conteúdo lúdico do livro para a gestão das escolas de Porto Feliz. </div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/3.jpg" alt="Foto 3" className="atv-img cap-img"></img>

          <div className="atv-text">Fala de Angélica Felício. </div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/4.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">Fala de Angélica Felício sobre Capítulo 5: “Brinquencanto – Jogo Sonoro Quem vive aqui?” </div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/5.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">A escritora Angélica Felício da Costa realizando explicação de atividade. </div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/6.jpg" alt="Foto 6" className="atv-img cap-img"></img>

          <div className="atv-text">Vídeo sobre atividade proposta do Capítulo 5 “Experimento – O Som”.</div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/7.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">Atividade prática com os Coordenadores referente ao Capítulo 4: "Briquencanto - Conexão da vida".</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/8.jpg" alt="Foto 8" className="atv-img cap-img"></img>

          <div className="atv-text">Capítulo 2: “Experimento - Microscópio Caseiro”</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/9.jpeg" alt="Foto 9" className="atv-img cap-img"></img>

          <div className="atv-text">Apresentação do escritor Rafael Ocanha sobre os rios regionais de Porto Feliz.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/10.jpeg" alt="Foto 10" className="atv-img cap-img"></img>

          <div className="atv-text">Fala do Rafael Ocanha sobre Bacias Hidrográficas e Unidades de Gerenciamento.</div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/11.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">Samara Rached e, ao fundo, QrCode disponível na contracapa dos livros para acesso ao site do Encanto dos Rios.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Porto+Feliz/12.jpg" alt="Foto 12" className="atv-img cap-img"></img>
        </div>
      </div>
    );
  }
}

export default CapacitacaoPortoFeliz;