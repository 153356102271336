import React, { Component } from 'react';

import MakingFoto1 from '../../assets/photos/making-foto1.jpeg';
import MakingFoto2 from '../../assets/photos/making-foto2.jpeg';
import MakingFoto3 from '../../assets/photos/making-foto3.jpg';
import MakingFoto4 from '../../assets/photos/making-foto4.jpg';
import MakingFoto5 from '../../assets/photos/making-foto5.JPG';
import MakingFoto6 from '../../assets/photos/making-foto6.JPG';
import MakingFoto7 from '../../assets/photos/making-foto7.JPG';

import './index.css';

class MakingOff extends Component {

  render() {
    return (
      <div>
        <div className="capacitacao-intro">
          <div className="page-title">Making Off</div>
        </div>

        <div className="making-photo-wrapper">
          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto1} alt="walter"></img>
            <div className="making-photo-text">Ensaio das músicas - Valter Silva, Tempo de Brincar</div>
          </div>

          <div className="making-photo-card">
            <video controls>
              <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Grava%C3%A7%C3%A3o+da+m%C3%BAsica+%C3%81gua+-+Valter+Silva%2C+Tempo+de+Brincar.mp4" type="video/mp4"></source>
              Seu navegador não suporta vídeos.
            </video>
            <div className="making-photo-text">Gravação da música Água - Valter Silva, Tempo de Brincar</div>
          </div>

          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto2} alt="samara"></img>
            <div className="making-photo-text">Escrita do conteúdo científico do Livro - Samara Rached</div>
          </div>

          <div className="making-photo-card">
            <video controls>
              <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Trabalho+de+harmoniza%C3%A7%C3%A3o+no+piano+-+Ang%C3%A9lica+Fel%C3%ADcio.mp4" type="video/mp4"></source>
              Seu navegador não suporta vídeos.
            </video>
            <div className="making-photo-text">Trabalho de harmonização no piano - Angélica Felício</div>
          </div>

          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto3} alt="website"></img>
            <div className="making-photo-text">Reunião sobre conteúdo do website</div>
          </div>

          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto4} alt="website"></img>
            <div className="making-photo-text">Produção do website - Wayne e Larissa</div>
          </div>

          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto5} alt="angelica"></img>
            <div className="making-photo-text">Angélica testando experimento</div>
          </div>

          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto6} alt="samara"></img>
            <div className="making-photo-text">Samara testando experimento</div>
          </div>

          <div className="making-photo-card">
            <img className="making-photo" src={MakingFoto7} alt="rafael"></img>
            <div className="making-photo-text">Rafael testando experimento</div>
          </div>
        </div>  
      </div>
    );
  }
}

export default MakingOff;
