import React, { Component } from 'react';

import LuceliaPhoto from '../../assets/photos/lucelia.jpeg';
import DaviPhoto from '../../assets/photos/davi.jpeg';
import GustavoPhoto from '../../assets/photos/gustavo.jpeg';
import AndrePhoto from '../../assets/photos/andre.jfif';
import AngelicaPhoto from '../../assets/photos/angelica.jpeg';
import SamaraPhoto from '../../assets/photos/samara.jpeg';
import RafaelPhoto from '../../assets/photos/rafael.jfif';
import FelipePhoto from '../../assets/photos/felipe.jpg';
import DouglasPhoto from '../../assets/photos/douglas.jfif';
import TempoBrincarPhoto from '../../assets/photos/tempobrincar.jpeg';
import RobertoPhoto from '../../assets/photos/roberto.jfif';
import AzulMostardaPhoto from '../../assets/photos/azulmostarda.jpeg';

import './index.css';

class Quem extends Component {

  render() {
    return (
      <div className='quem'>
        <div className="page-title">Quem somos nós</div>
        <div>
          <div className="quem-title">ONG GTA Jerivá</div>
          <div className="quem-initial-text">O Grupo de Trabalho Ambiental “Jerivá” (GTA Jerivá) foi criado em 2004, com a missão de atender questões ambientais do município de Votorantim (SP) e de cidades da região. A ONG é composta por amigos de diversas áreas do conhecimento como da agroecologia, biologia, educação, direito e engenharia.
            A atuação do GTA Jerivá em relação a proteção dos recursos hídricos é contínua, como no monitoramento e análise físico-química da água da represa Itupararanga, reservatório importante para abastecimento de diversos municípios, incluindo Votorantim e Porto Feliz. Realiza a catalogação
            e diagnóstico das nascentes de Votorantim para a sua proteção, e realiza plantios de árvores nativas em Áreas de Preservação Permanentes e áreas degradadas.
            O GTA Jerivá participa ativamente nos debates em prol do meio ambiente como em 2018 no 8º Fórum Mundial da Água em Brasília; nas consultas públicas da revisão do plano de bacias; no Comitê de Bacias Hidrográficas do Rio Sorocaba e Médio Tietê (CBH-SMT) e atualmente possui uma cadeira no Conselho Municipal de Defesa do Meio Ambiente (COMDEMA) de Votorantim.
          </div>
          <div className="quem-photo-wrapper">
            <div className="quem-photo-card">
              <div className="quem-photo-name">Lucélia Matilde Ferrari</div>
              <img className="quem-photo" src={LuceliaPhoto} alt="lucelia"></img>
              <div className="quem-photo-text">Graduada em direito pela Faculdade de Direito de Sorocaba, especialista em Sistema Integrado de Gestão Ambiental e possui formação complementar em Elaboração de Projetos pela UFSCar Sorocaba. Atua em defesa do meio ambiente e é voluntária da ONG GTA Jerivá, a qual é a atual presidente. Acredita na luta e defesa pelo meio ambiente para a construção de uma sociedade justa e sustentável.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Davi Santos Genesi</div>
              <img className="quem-photo" src={DaviPhoto} alt="davi"></img>
              <div className="quem-photo-text">Pintor e Técnico em Turismo Rural pelo SENAR, teve o primeiro contato com a natureza através de aulas de Educação Ambiental recebidas do seu pai, o Sr. Oswaldo Genesi. Atuante na defesa do meio ambiente desde 1978, foi sócio-fundador da GTA Jerivá em 1992, participou (e continua participando!) de importantes marcos ambientais regionais. Acredita que cultura e meio ambiente são indissociáveis, e é um  eterno apaixonado pela natureza.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Gustavo Rangel Gil Miguel</div>
              <img className="quem-photo" src={GustavoPhoto} alt="gustavo"></img>
              <div className="quem-photo-text">Turismólogo, atua na área ambiental desde 2006. Busca levar a Educação Ambiental para o maior número de pessoas. Acredita que através da conscientização transformamos o mundo em um lugar melhor.</div>
            </div>
          </div>
        </div>
        <div>
          <div className="quem-title">Equipe Técnica “Encanto dos Rios”</div>
          <div className="quem-photo-wrapper">
            <div className="quem-photo-card">
              <div className="quem-photo-name">André Cordeiro Alves dos Santos</div>
              <img className="quem-photo" src={AndrePhoto} alt="andre"></img>
              <div className="quem-photo-text">Biólogo, Mestre e Doutor em Ciências da Engenharia Ambiental. Professor da UFSCar - Campus Sorocaba, orientador de mestrado e atua no Comitê de Bacias do Rio Sorocaba e Médio Tietê, onde exerce atualmente a vice-presidência. Atua nas áreas da Limnologia, Microbiologia Ambiental e Gestão de Recursos Hídricos. Acredita que um outro mundo é possível e desejável, com uma outra forma de relação entre a humanidade e o meio ambiente sem a exploração capitalista.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Angélica Felício da Costa</div>
              <img className="quem-photo" src={AngelicaPhoto} alt="angelica"></img>
              <div className="quem-photo-text">Bióloga (UFSCar Sorocaba e Universidad de Málaga/Espanha), pós-graduada em Educação (UFSCar Sorocaba). Professora de Ciências, palestrante, musicista e escritora na área de divulgação científica. Acredita que cada ser vivo emana uma bela melodia que compõem a orquestra da vida.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Samara Rached Souza</div>
              <img className="quem-photo" src={SamaraPhoto} alt="samara"></img>
              <div className="quem-photo-text">Bióloga, pós-graduada em gestão de recursos hídricos (CEDEP) e Mestre em Sustentabilidade na Gestão Ambiental (UFSCar Sorocaba). Trabalha com catalogação de nascentes e projetos de conservação e recuperação ambiental. Recebeu destaques em concursos regionais na produção de textos literários e científicos. Acredita que podemos mudar o mundo.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Rafael Ocanha Lorca Neto</div>
              <img className="quem-photo" src={RafaelPhoto} alt="rafael"></img>
              <div className="quem-photo-text">Biólogo, Mestre em Sustentabilidade na Gestão Ambiental (UFSCar Sorocaba). Especialista na área de mapeamento utilizando o Sistema de Informação Geográfica; atua no planejamento e gestão de bacias hidrográficas, catalogação de nascentes e recuperação de áreas degradadas. Acredita que projetos regionais são a base para a solução dos problemas globais.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Felipe Zamboni Landulfo</div>
              <img className="quem-photo" src={FelipePhoto} alt="felipe"></img>
              <div className="quem-photo-text">Gestor Ambiental, pós-graduado em Agricultura Natural. Trabalha com consultoria, ensinando o cultivo sem agrotóxicos e o despertar para o respeito e cuidado com o nosso meio ambiente. Atua como voluntário das causas ambientais desde 2001. Acredita na melhoria da qualidade de vida aos que praticam e consomem os alimentos oriundos dessa prática.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Douglas “O Reticolo” Nogueira</div>
              <img className="quem-photo" src={DouglasPhoto} alt="douglas"></img>
              <div className="quem-photo-text">Mineiro radicado no interior de São Paulo, desde criança é apaixonado por arte e cultura. Formado em Design, encontrou em uma paixão antiga: O desenho, o seu objetivo de vida. Ama criar criaturas inexistentes, falar sobre folclore, música, pintar e estudar sobre ilustração e arte. Apaixonado desde criança pelo universo de Harry Potter (desde que leu A Pedra Filosofal aos onze anos), acredita que sempre é possível colocar um pouco de magia em suas criações.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Cia. Tempo de Brincar</div>
              <img className="quem-photo" src={TempoBrincarPhoto} alt="tempobrincar"></img>
              <div className="quem-photo-text">Fundada no ano de 2003 e representada por Valter Silva e Elaine Buzato. Cria os próprios espetáculos cênico-musicais unindo cantigas tradicionais, mitos e ritmos musicais brasileiros em composições originais, costuradas por teatros de bonecos, cenários, figurinos e adereços. Acredita na importância de brincar com a criança adormecida dentro de cada um de nós, e transmitir às gerações futuras a cultura do nosso povo.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Azul Mostarda</div>
              <img className="quem-photo" src={AzulMostardaPhoto} alt="azulmostarda"></img>
              <div className="quem-photo-text">Fundada por Leonardo Oliveira Simões e aqui representado por Larissa Oliveira Simões e Wayne Almeida da Conceição, a Azul Mostarda é uma empresa de produção audiovisual e tecnologia em sistemas e e-commerce. Acredita no poder do otimismo e da esperança de um mundo melhor e mais colorido.</div>
            </div>
            <div className="quem-photo-card">
              <div className="quem-photo-name">Gráfica Santa Edwiges, representada por Roberto Mazzer Neto</div>
              <img className="quem-photo" src={RobertoPhoto} alt="roberto"></img>
              <div className="quem-photo-text">Empresário gráfico, economista e representante da Santa Edwiges, gráfica com vasta tradição no mercado paulista. Com 45 anos de existência, localizada na cidade de Tietê-SP, a Santa Edwiges desenvolve projetos personalizados junto a seus parceiros e possui certificação FSC a fim de garantir a produção sustentável para seus clientes. A empresa acredita no desenvolvimento de projetos gráficos inovadores que impactem positivamente a vida das pessoas.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Quem;