import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import steamEng from '../../assets/ENGINEERING - STEAM.png';
import steamArt from '../../assets/ART - STEAM.png';
import steamScience from '../../assets/SCIENCE - STEAM.png';
import steamMath from '../../assets/MATHEMATIC - STEAM.png';
import './index.css';

class AtvCap4 extends Component {

  render() {
    return (
      <div>
        <div className="atv-cap4-intro">
          <div className='page-title'>CAPÍTULO 4: O QUE É UMA BACIA HIDROGRÁFICA?</div>
          <div className="atv-text">O Capítulo 4 explora a continuação da viagem da gotinha, apresentada no capítulo 2, e a sua despedida, que ao se juntar com outras gotinhas compôs um rio e seguiu seu caminho rumo ao horizonte e além! Nesta atmosfera de mistérios e imaginação é exercitado a curiosidade, a busca por respostas e soluções com base nos conhecimentos das Ciências da Natureza, como no conceito bacia hidrográfica.</div>
        </div>

        <div>
          <div className='page-title page-subtitle'>BRINQUENCANTO: CONEXÃO DA VIDA <img src={steamEng} alt="ENGENHARIA" className="atv-ico"></img><img src={steamMath} alt="MATEMATICA" className="atv-ico"></img></div>
          <div className="atv-text">Estamos conectados, não somente através das redes sociais, mas também em ações no cuidado com o meio ambiente. Se plantarmos uma flor além de embelezar o ambiente, favorecemos os insetos polinizadores que adoram o néctar que ela produz. Nossas boas ações, por mais simples que pareçam, ressoam em outro ser vivente. O mesmo ocorre com uma bacia hidrográfica, para termos água de qualidade é necessário que todos façam sua parte, pois os rios são conectados, assim como vimos no capítulo 4. Se uma cidade não cuida bem do seu rio e polui suas águas, ela irá chegar nas outras cidades em seu entorno levando desequilíbrio e doenças. Pensando nessa teia de conexão propomos uma brincadeira que divertirá as crianças mas também irá propor a reflexão sobre nossas ações como sociedade.</div>
          <div className="atv-text">Os passos a seguir descrevem o brinquencanto do capítulo 4:</div>
          <ul className="how-to">
            <li>Coloque as crianças em círculo.</li>
            <li>Em círculo, cada criança deve segurar um pedaço do barbante com a mão direita e seu prolongamento passar para o próximo da frente, de modo que a linha do barbante seja desenrolada à medida que seja passada de uma criança à outra. O barbante não deve ser passado de lado e sim de frente para formar um grande teia de fio seja formada no centro do círculo.</li>
            <li>A primeira criança que segurou o barbante será também a última a receber, e dessa forma juntar as pontas do barbante com um nó.</li>
            <li>O centro do círculo, onde se encontra a teia de barbante deve ser juntado com auxílio de outro barbante. Deixe a teia central solta para que uma criança possa puxar seu barbante.</li>
            <li>Peça para que uma das crianças puxe o barbante e a próxima criança que sentir o movimento da linha também puxe com a outra mão, peça para outra repetir esse movimento sucessivamente, demonstrando que todos estão conectados nessa grande teia.</li>
            <li>Como desafio prenda uma caneta no barbante da teia central da roda e peça para que coloquem a caneta dentro de um copo e em seguida uma garrafa aumentando cada vez mais a o grau de dificuldade e concentração.</li>
            <li>Relacione essa atividade com a conexão que temos com outros seres viventes e responsabilidade que temos em fazer nossa parte no cuidado do meio ambiente, destacando a conexão dos rios nas bacias hidrográficas e a cadeia trófica.</li>
          </ul>
        </div>

        <div>
          <div className='page-title page-subtitle'>EXPERIMENTO: CAMINHO ENCANTADO DO RIO <img src={steamEng} alt="ENGENHARIA" className="atv-ico"></img><img src={steamArt} alt="ARTE" className="atv-ico"></img><img src={steamScience} alt="CIENCIA" className="atv-ico"></img></div>
          <div className="atv-text">A água do rio segue um caminho, seu início é nas nascentes em seguida percorre longas viagens cortando vilas, cidades, florestas, campos, virando cachoeira; ela acaba desaguando em um outro rio, ou em um lago, lagoa, mar ou mesmo no oceano. O curso d’água é um belo desenho tortuoso na paisagem, que pode ser melhor apreciado em uma grande altitude como ocorre nas fotografias feitas por satélites; essas fotos, ou melhor dizendo esses mapas, são usados por profissionais nas áreas de ciência e engenharia para estudar a bacia hidrográfica da região. E se ilustrarmos os caminhos de um rio? Vamos fazer um “caminho encantado invisível” que é revelado quando quisermos!</div>
          <div className="atv-text"><b>Vamos precisar de:</b></div>
          <ul className="material-list">
            <li>2 colheres de amido de milho;</li>
            <li>2 copos de água;</li>
            <li>15 gotas de iodo;</li>
            <li>Pincel ou cotonete;</li>
            <li>Plástico reutilizável (como os de tampas);</li>
            <li>Panela.</li>
          </ul>
          <div className="atv-text"><b>Como fazer</b></div>
          <ul className="how-to">
            <li>A professora ou o professor irá preparar uma solução que será a “tinta invisível”, para isso irá misturar o amido na água em uma panela e levar ao fogo até formar uma calda transparente.</li>
            <li>Em seguida as crianças deverão passar o pincel na solução e ilustrar traços que simbolizam um rio, seus afluentes e nascentes. Incentive-os a desenhar diferentes e criativas formas. Nas páginas 12 e 13 do livro há um mapa da hidrografia dos municípios de Votorantim e Porto Feliz que poderá ser utilizado como base. A ilustração deverá ser feita em um papel marrom, podendo ser papelão, saco de pão, sacola de compra.</li>
            <li>Espere secar e os traços que foram desenhados pela “tinta invisível" que irão “desaparecer”. A água irá evaporar, mas o resto da solução que é amido irá continuar no papel e não aparecerá.</li>
            <li>Quando quiser que o desenho do caminho do rio seja revelado, passe o pincel ou o cotonete no iodo e peça para as crianças passarem por cima da ilustração. Assim que o iodo pintar o rastro da solução da tinta invisível, ele ficará mais escuro em relação ao plástico reutilizável (como os de tampas) e aparecerão como encanto os traços das nascentes, rios e afluentes desenhados pelos alunos e alunas.</li>
          </ul>
          <video controls className="cap4-img">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Caminho+encantado.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>
          <div className="page-title page-subtitle page-subtitle-small">Conversando sobre o experimento: caminho encantado</div>
          <div className="atv-text">Após o experimento, o que podemos concluir?</div>
          <div className="atv-text">Professor (a) converse com os estudantes sobre o rio principal que passa na sua cidade e curso de água, que é a direção que ela veio e o caminho que ela segue (<b>essa conversa envolve a habilidade EF05Cl03</b>), faça relação com os mapas didáticos dos rios principais da sua cidade presentes no capítulo 4 do livro Encanto dos Rios e disponível para download neste site. Ressalte também a importância da preservação das nascentes na formação dos rios, citando que ela começa com pouca água mas no final alimenta grandes bacias. Neste experimento é possível também ser explorado conceitos como solução, e a reação do amido com o iodo que é a formação de um complexo de uma cor azulada. </div>
        </div>

        <div>
          <div className='page-title page-subtitle'>CANTENCANTO <img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
          <div className="atv-text">Neste CantaEncanto propomos o acompanhamento lúdico e rítmico da música Olho d'água através da utilização de dois copos plásticos e uma colher. Os estudantes poderão acompanhar a canção batendo a colher em um dos copos contendo água pela metade e em seguida irão bater a colher no copo vazio que produzirá um som mais agudo em relação ao primeiro. Essa exploração sonora pode compor um lindo e envolvente acompanhamento do ritmo da música “Olho d’água”.</div>

          <div className="music-title">OLHO D'ÁGUA</div>
          <div className="music-composition">Composição: Valter Silva</div>
          <div>
            <div>Olho d´água surgiu</div>
            <div>Mais um rio que nasceu</div>
            <div>Um outro olho se abriu </div>
            <div>Ribeirão encheu</div>
            <div className="music-refrao">Refrão</div>
            <div>Virou cachoeira</div>
            <div>Corredeira num fio </div>
            <div>Em busca do mar </div>
            <div>Numa dança de paz </div>
            <div>Natureza sorriu </div>
            <div>Cantando ao sol </div>
            <div>Melodia se ouviu</div>
            <div>Caindo na terra </div>
            <div>Cristais feito gotas </div>
            <div>Voltando pro rio.</div>
          </div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Olho+d_%C3%A1gua.mp3" target="_blank">Download da música</Button></div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Olho+d_%C3%A1gua+.pdf" target="_blank">Download da letra e cifra</Button></div>
        </div>
      </div>
    );
  }
}

export default AtvCap4;