import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import { Layout, Menu, Drawer, Button } from 'antd';
import { isBrowser, isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import LivroLogo from '../../assets/encanto_logo.png';
import GTALogo from '../../assets/gta.png';
import SPLogo from '../../assets/sp.png';
import FehidroLogo from '../../assets/fehidro.png';

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

const AppLayout = () => {
  const [isVisible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  if (isBrowser) {
    return (
      <Layout>
        <Sider width={270} style={{
          overflow: 'hidden',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}>
          <div className="livro-logo-menu-wrapper">
            <img className="livro-logo-menu" src={LivroLogo} alt="encantologo"></img>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
          >

            <Menu.Item key="main1">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="main2">
              <Link to="/quem">Quem somos nós</Link>
            </Menu.Item>
            <Menu.Item key="main3">
              <Link to="/profLogin">
                Acesso aos Coordenadores
              </Link>
            </Menu.Item>
            <SubMenu key="subAtiv" title="Atividades dos Capítulos">
              <Menu.Item key="sub1">
                <Link to="/atividadesIntroducao">Introdução</Link>
              </Menu.Item>
              <Menu.Item key="sub2">
                <Link to="/atividadesCap1">1 - A água nos Cosmos</Link>
              </Menu.Item>
              <Menu.Item key="sub3">
                <Link to="/atividadesCap2">2 - O ciclo da água</Link>
              </Menu.Item>
              <Menu.Item key="sub4">
                <Link to="/atividadesCap3">3 - A água acaba?</Link>
              </Menu.Item>
              <Menu.Item key="sub5">
                <Link to="/atividadesCap4">4 - Bacia Hidrográfica</Link>
              </Menu.Item>
              <Menu.Item key="sub6">
                <Link to="/atividadesCap5">5 - Quem vive aqui?</Link>
              </Menu.Item>
              <Menu.Item key="sub7">
                <Link to="/atividadesCap6">6 - Conhecer para cuidar</Link>
              </Menu.Item>
              <Menu.Item key="sub8">
                <Link to="/atividadesRio">O Rio da Minha Escola</Link>
              </Menu.Item>
              <Menu.Item key="sub9">
                <Link to="/atividadesOque">O que é isso que eu vi no livro?</Link>
              </Menu.Item>
              <Menu.Item key="sub10">
                <Link to="/atividadesRef">Referências</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="subCap" title="Capacitação">
              <Menu.Item key="sub11">
                <Link to="capacitacao">Introdução</Link>
              </Menu.Item>
              <Menu.Item key="sub12">
                <Link to="capacitacaoPortoFeliz">Porto Feliz</Link>
              </Menu.Item>
              <Menu.Item key="sub13">
                <Link to="capacitacaoVotorantim">Votorantim</Link>
              </Menu.Item>
            </SubMenu>            
            <Menu.Item key="main4">
              <Link to="makingOff">Making Off</Link>
            </Menu.Item>
            <Menu.Item key="main5">
              <Link to="agradecimentos">Agradecimentos</Link>
            </Menu.Item>
          </Menu>
          <div className="logos-wrapper">
            <img className="logo-extra" src={GTALogo} alt="gta jeriva"></img>
            <img className="logo-extra" src={SPLogo} alt="estado de sp"></img>
            <img className="logo-extra" src={FehidroLogo} alt="fehidro"></img>
          </div>
        </Sider>

        <Layout style={{ padding: '0 24px 24px', marginLeft: 230 }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              overflow: 'initial'
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    )

  } else if (isMobile) {
    return (
      <Layout>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Button onClick={showDrawer}>
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </Header>
        <Drawer placement="left" onClose={onClose} visible={isVisible}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
          >
            <Menu.Item key="1" onClick={onClose}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={onClose}>
              <Link to="/quem">Quem somos nós</Link>
            </Menu.Item>
            <Menu.Item key="3" onClick={onClose}>
              <Link to="/profLogin">
                Acesso aos Coordenadores
              </Link>
            </Menu.Item>
            <SubMenu key="4" title="Atividades dos Capítulos">
              <Menu.Item key="5" onClick={onClose}>
                <Link to="/atividadesIntroducao">Introdução</Link>
              </Menu.Item>
              <Menu.Item key="6" onClick={onClose}>
                <Link to="/atividadesCap1">1 - A água nos Cosmos</Link>
              </Menu.Item>
              <Menu.Item key="7" onClick={onClose}>
                <Link to="/atividadesCap2">2 - O ciclo da água</Link>
              </Menu.Item>
              <Menu.Item key="8" onClick={onClose}>
                <Link to="/atividadesCap3">3 - A água acaba?</Link>
              </Menu.Item>
              <Menu.Item key="9" onClick={onClose}>
                <Link to="/atividadesCap4">4 - Bacia Hidrográfica</Link>
              </Menu.Item>
              <Menu.Item key="10" onClick={onClose}>
                <Link to="/atividadesCap5">5 - Quem vive aqui?</Link>
              </Menu.Item>
              <Menu.Item key="11" onClick={onClose}>
                <Link to="/atividadesCap6">6 - Conhecer para cuidar</Link>
              </Menu.Item>
              <Menu.Item key="12" onClick={onClose}>
                <Link to="/atividadesRio">O Rio da Minha Escola</Link>
              </Menu.Item>
              <Menu.Item key="13" onClick={onClose}>
                <Link to="/atividadesOque">O que é isso que eu vi no livro?</Link>
              </Menu.Item>
              <Menu.Item key="14" onClick={onClose}>
                <Link to="/atividadesRef">Referências</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="15" title="Capacitação">
              <Menu.Item key="16" onClick={onClose}>
                <Link to="capacitacao">Introdução</Link>
              </Menu.Item>
              <Menu.Item key="17" onClick={onClose}>
                <Link to="capacitacaoPortoFeliz">Porto Feliz</Link>
              </Menu.Item>
              <Menu.Item key="18" onClick={onClose}>
                <Link to="capacitacaoVotorantim">Votorantim</Link>
              </Menu.Item>
            </SubMenu>            
            <Menu.Item key="19" onClick={onClose}>
              <Link to="makingOff">Making Off</Link>
            </Menu.Item>
            <Menu.Item key="20" onClick={onClose}>
              <Link to="agradecimentos">Agradecimentos</Link>
            </Menu.Item>
          </Menu>
          <div className="logos-wrapper">
            <img className="logo-extra" src={GTALogo} alt="gta jeriva"></img>
            <img className="logo-extra" src={SPLogo} alt="estado de sp"></img>
            <img className="logo-extra" src={FehidroLogo} alt="fehidro"></img>
          </div>
        </Drawer>
        <Content><Outlet /></Content>
      </Layout>
    )
  }
}


export default AppLayout;