import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import './index.css';

class Quem extends Component {

  render() {
    return (
      <div>
        <div className='intro page-title'>Olá Coordenador (a), seja bem-vindo (a) !!!</div>
        <div className="atv-text">Temos algumas obrigações junto ao Governo do Estado, e precisaremos da sua ajuda para cumpri-las.</div>
        <div className="atv-text">Dentro de cada livro há uma cópia impressa do “Formulário de Entrega” que deverá ser preenchido por cada professor. Por gentileza, solicitar a devolução desse Formulário e nos envie escaneado/foto para o email <a href="mailto:projetoencantodosrios@gmail.com">projetoencantodosrios@gmail.com</a> até <b>30 de Abril de 2023</b>.</div>

        <div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Formul%C3%A1rio+(impresso+e+entregue+junto+ao+livro).pdf" target="_blank">Download do Formulário de Entrega (caso algum professor perca e você precise de mais uma cópia!)</Button></div>
        </div>

        <div className="atv-text">Pedimos a gentileza de tirarem fotos da capacitação entre coordenação e professores, e podem nos encaminhar junto com os formulários ou via whatsapp, que iremos colocar o trabalho de vocês no site.</div>
        <div className="atv-text">Após a Capacitação que vocês Coordenadores farão junto aos Professores, precisaremos que cada professor realize o preenchimento de um <b>Relatório de Atividades</b>, comprovando a utilização do livro em sala de aula. Para isso, já deixamos um modelo de relatório pronto, que pode ser enviado aos professores para orientá-los nessa missão.</div>
        <div className="atv-text">Pedimos a gentileza de nos encaminharem os relatórios feitos por cada professor em pdf até <b>30 de junho de 2023</b> para o e-mail <a href="mailto:projetoencantodosrios@gmail.com">projetoencantodosrios@gmail.com</a>.</div>

        <div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/RELAT%C3%93RIO+DO+PROFESSOR+-+ENCANTO+DOS+RIOS.docx" target="_blank">Download do Modelo de Relatório de Atividades (formato Microsoft Word)</Button></div>

          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/RELAT%C3%93RIO+DO+PROFESSOR+-+ENCANTO+DOS+RIOS.docx.pdf" target="_blank">Download do Modelo de Relatório de Atividades (formato PDF)</Button></div>
        </div>

        <div className="atv-text">Muito obrigada, e qualquer dúvida ou esclarecimento estamos à disposição!</div>
      </div>
    );
  }
}

export default Quem;