import React, { Component } from 'react';

import './index.css';

class AtvRef extends Component {

  render() {
    return (
      <div>
        <div className="atv-ref-intro">
          <div className='page-title'>Referências</div>

          <div className="atv-text">Músicas do Projeto “Encanto dos Rios” podem ser acessadas pelo Spotify: https://open.spotify.com/intl-pt/album/5jV4Umg08mHoP9hpgRN8lk</div>
          <div className="atv-text">Nova Escola. Disponível em: https://novaescola.org.br/conteudo/11683/o-que-e-o-stem-e-como-ele-pode-melhorar-a-sua-aula. Acesso 10 de março de 2021.</div>
          <div className="atv-text">Olimpíadas Brasileiras de Astronomia e Astronáutica. Disponível em: http://www.oba.org.br/site/ . Acesso 03 de outubro de 2022.</div>
          <div className="atv-text">STELLARIUM. Disponível em : https://stellarium.org/pt/ . Acesso: 03 de outubro de 2022.</div>
          <div className="atv-text">Manual do Mundo. Disponível em https://manualdomundo.uol.com.br/experiencias-e-experimentos/microscopio-caseiro-com-laser-experiencia-de-fisica-e-biologia/. Acesso em 08 de agosto de 2021.</div>
          <div className="atv-text">ALVES, Adenirto Jefferson Gomes, et al. Microscópio caseiro. III Congresso Nacional de Educação. Natal/ RN, 2016. Disponível em: https://www.editorarealize.com.br/editora/anais/conedu/2016/TRABALHO_EV056_MD4_SA18_ID5899_11082016204748.pdf.</div>
          <div className="atv-text">PLANINSIC, Gorazd. Water-Drop Projector. Department of Physics, University of Ljubljana, Jadranska 19, 1000 Ljubljana. Disponível em: https://www.fmf.uni-lj.si/~planinsic/articles/planin2.pdf.  Acesso em 02 de outubro de 2022.</div>
          <div className="atv-text">365 Science Activities da editora Usborne. DICKINS, Rosie; CHISHOLM, Jane; ROBSON, Kirsteen. 365 Science activities. Usborne Activities, Londres, 2014, p.22.</div>
          <div className="atv-text">Nature: Mudanças na produção e consumo de alimentos aliadas ao planejamento territorial inteligente podem frear perda de biodiversidade e começar a regenerar o planeta até 2050. Disponível em: https://www.iis-rio.org/publicacoes/mudancas-na-producao-e-consumo-de-alimentos-aliadas-a-planejamento-territorial-inteligente-podem-frear-perda-de-biodiversidade-e-comecar-a-regenerar-o-planeta-ate-2050/. Acesso em 21 de agosto de 2021.</div>
          <div className="atv-text">TECNOBLOG. Robô inseto Jesus. Disponível em: https://www.tecmundo.com.br/ciencia/84098-pesquisadores-criam-aranha-agua-robotica-totalmente-funcional.htm . Acesso em 21 de agosto de 2021.</div>
          <div className="atv-text">LIMNONEWS. Meu mundo desabou: tragédia dos gerridae. Disponível em:https://limnonews.wordpress.com/2018/11/01/meu-mundo-desabou-a-terrivel-tragedia-dos-gerridae/. Acesso em 21 de agosto de 2021.</div>
          <div className="atv-text">Manual do  Mundo: como enxergar a voz. Disponível em: https://manualdomundo.uol.com.br/experiencias-e-experimentos/como-enxergar-a-voz/ .  Acesso em 03 de outubro de 2022.</div>
          <div className="atv-text">National Geográfic. Disponível em : https://www.nationalgeographicbrasil.com/animais/2021/02/veja-como-funciona-a-ecolocalizacao-o-sonar-inerente-da-natureza . Acesso em 03 de outubro de 2022.</div>
        </div>
      </div>
    );
  }
}

export default AtvRef;