import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import steamEng from '../../assets/ENGINEERING - STEAM.png';
import steamScience from '../../assets/SCIENCE - STEAM.png';
import steamMath from '../../assets/MATHEMATIC - STEAM.png';
import steamArt from '../../assets/ART - STEAM.png';
import Exp1Foto1 from '../../assets/photos/cap6-foto1.jpg';
import Exp1Foto2 from '../../assets/photos/cap6-foto2.jpg';
import Exp1Foto3 from '../../assets/photos/cap6-foto3.jpg';
import Exp1Foto4 from '../../assets/photos/cap6-foto4.png';
import './index.css';

class AtvCap6 extends Component {

  render() {
    return (
      <div>
        <div>
          <div className='page-title'>CAPÍTULO 6: COMO CUIDAR DA NOSSA ÁGUA?</div>
          <div className="atv-text">Durante a aventura da leitura do livro Encanto dos Rios, não encontramos um planeta tão cheio de vida, encanto e musical como o nosso. Vimos como nossas árvores são valiosas heranças deixadas pela história da terra; aprendemos que nossos rios levam a vida e a alegria por onde passam e observamos que nossos animais compõem uma linda orquestra viva de sons com seus cantos, aulidos, zumbidos e coaxadas. Dessa forma convidamos todos a se juntar à importante missão de cuidar da água e da vida do nosso Planeta. Essa missão precisa ser realizada em conjunto, pois fazemos parte da natureza e estamos conectados. Ninguém pode viver sem este Planeta, embora ele possa continuar existindo sem nós.</div>
        </div>

        <div>
          <div className='page-title page-subtitle'>BRINQUENCANTO: SOMOS NATUREZA <img src={steamScience} alt="CIENCIA" className="atv-ico"></img> <img src={steamArt} alt="ARTE" className="atv-ico"></img> <img src={steamEng} alt="ENGENHARIA" className="atv-ico"></img></div>
          <div className="atv-text">Neste brincaencanto propomos uma atividade de recorte e colagem de uma arte pop-up que por “encanto” se moverá. O processo da construção pode ser realizada pelo professor, ou ainda adaptada para sua turma. O objetivo é instigar a curiosidade, a imaginação, a criatividade e a brincadeira com o <i>pop-up</i> formado.</div>
          <div className="atv-text">Essa colagem em 3D é com a mão, presente na página 11, da bacia hidrográfica. Neste <i>pop-up</i> a mão se move a cada vez que abre o papel. Para construir, siga as instruções no vídeo a seguir.</div>

          <video controls className="cap6-img">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Dobradura+da+m%C3%A3o.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Dobradura+da+m%C3%A3o.pdf" target="_blank">Download da arte da mão</Button></div>
        </div>

        <div>
          <div className='page-title page-subtitle'>EXPERIMENTO: IMPORTÂNCIA DAS FLORESTAS <img src={steamScience} alt="Imagem STEAM: CIÊNCIA " className="atv-ico"></img><img src={steamEng} alt="Imagem STEAM: ENGENHARIA " className="atv-ico"></img><img src={steamMath} alt="Imagem STEAM: MATEMATICA " className="atv-ico"></img></div>
          <div className="atv-text">Uma das ações humanas que mais fazem mal aos rios são os desmatamentos das árvores que ficam no seu entorno, chamadas de mata ciliares. Quando é retirada a mata ciliar ocorre a queda de sedimentos dentro do rio, principalmente em dias chuvosos. Esse processo se chama assoreamento e muda a paisagem dificultando  a passagem e o caminho da água; afetando a qualidade do rio e dos seres vivos que dependem diretamente e indiretamente dele.  As raízes das árvores das matas ciliares protegem o rio, porque elas seguram os sedimentos e diminuem o efeito do assoreamento dos rios. Neste experimento vamos observar a importância das raízes das plantas em segurar sedimentos e proteção dos corpos d'água.</div>
          <div className="atv-text">Vamos precisar de:</div>
          <ul className="material-list">
            <li>4 garrafas PETs transparentes;</li>
            <li>Barbante;</li>
            <li>Terra orgânica;</li>
            <li>Tesoura;</li>
            <li>Pacotinho de sementes como Flor de Dália, Salsinha, Alecrim Rosmarinho, Orégano e etc (que são vendidas no supermercado) ou alguma planta de tamanho pequeno ou médio para ser replantado como moranguinho, peixinho, cebolinha e etc.</li>
          </ul>
          <div className="atv-text">Como fazer:</div>
          <ul className="how-to">
            <li>Com a tesoura, corte a lateral de cada garrafa PET, conforme a imagem a seguir:</li>
            <img src={Exp1Foto4} alt="Imagem das garrafas cortadas" className="atv-img cap6-img"></img>
            <li>Coloque a dentro das garrafas cortada a terra orgânica.</li>
            <li>Agora é de sua preferência professor(a). Se quiser trabalhar o desenvolvimento das plantas englobando outras competências e habilidades com seus alunos e tiver tempo, plante as sementes dos pacotinhos na terra de uma das garrafas. Sua germinação e amadurecimento podem levar de semanas a meses. Contudo, se o objetivo é mais direto como nos conceitos de assoreamento e matas ciliares, poderá replantar um vegetal já amadurecido de tamanho médio ou pequeno em uma das garrafas. É aconselhado deixar dois ou três dias plantado antes de prosseguir o experimento, para que a planta se adapte ao recipiente.</li>
            <div>
              <img src={Exp1Foto1} alt="Garrafas com terra e sementes brotando" className="atv-img cap6-img"></img>
              <img src={Exp1Foto2} alt="Morango plantado" className="atv-img cap6-img cap6-img-col"></img>
            </div>
            <li>Corte pela metade duas garrafas PETs, faça dois buraquinho na lateral para amarrar os barbantes como um cesto.</li>
            <li>Coloque o barbante das garrafas no bico da garrafa com a planta e da garrafa com a terra na ponta de uma mesa. Retire a tampa das garrafas.</li>
            <li>Molhe a garrafa com a terra e a garrafa com a terra e a planta. Incline as garrafas devagar  deixando que a água saia do bico e escorra para dentro dos recipientes em forma de cesto.</li>
            <img src={Exp1Foto3} alt="Experimento assoreamento" className="atv-img cap6-img"></img>
          </ul>
          <div className="atv-text">Espera-se que a água que passou pela garrafa com terra sairá com mais sedimentos e escura comparado à garrafa com a planta. Se isso não ocorrer, pode ser devido a dois fatores: a raiz não se fixou à terra, ou foi colocada uma quantidade exagerada de água. Teste antes de mostrar para as crianças.   </div>

          <div className="page-title page-subtitle page-subtitle-small">Conversando sobre o experimento: Assoreamento</div>
          <div className="atv-text">Como é a cor da água nos dois recipientes? Por que a água que escorreu da garrafa que tinha apenas terra é mais escura do que a garrafa que tinha planta?</div>
        </div>

        <div>
          <div className='page-title page-subtitle'>CANTAENCANTO <img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
          <div className="music-title">Quintal do Brasil </div>

          <div>
            <div>Rabo de tucano </div>
            <div>Rosa do campo </div>
            <div>Sucupira preta </div>
            <div>Salta martim </div>
            <div>Acoita cavalo </div>
            <div>Anjico do morro </div>
            <div>Balsaminho, barreiro </div>
            <div>Uvaia, aguaí </div>
            <div>Tamboril, Tanheiro, Tarumã </div>
            <div>Timbó e tinguí </div>
            <div>Pau santo, Pitanga, Palmeira </div>
            <div>Iça Guaraparim Ipê amarelo Gabiroba, araçá </div>
            <div>Angelim do cerrado </div>
            <div>Imbuia, ingá </div>
            <div>Vassoura vermelha </div>
            <div>Olho de cabra azul </div>
            <div>Figueira branca </div>
            <div>Catiguá, babaçú </div>
            <div>Muricí, monjoleiro </div>
            <div>Piqui, papa goela, Urucum, batatá </div>
            <div>Manguerana, marmelo </div>
            <div>Caxeta, formoso nasceu Jerivá </div>
            <div>Plantei no meu peito </div>
            <div>O som de um quintal </div>
            <div>Se quiser uma sombra </div>
            <div>Água fresca e paz </div>
            <div>Não regue o final.</div>
          </div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Quintal+do+Brasil.mp3" target="_blank">Download da música</Button></div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Quintal+do+Brasil.pdf" target="_blank">Download da letra e cifra</Button></div>
        </div>
      </div>
    );
  }
}

export default AtvCap6;