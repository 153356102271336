import React, { Component } from 'react';

import AtvOqueFoto1 from '../../assets/photos/capoque-foto1.png';
import AtvOqueFoto2 from '../../assets/photos/capoque-foto2.png';
import AtvOqueFoto3 from '../../assets/photos/capoque-foto3.png';
import AtvOqueFoto4 from '../../assets/photos/capoque-foto4.png';
import AtvOqueFoto5 from '../../assets/photos/capoque-foto5.png';
import AtvOqueFoto6 from '../../assets/photos/capoque-foto6.png';

import './index.css';

class AtvOque extends Component {

  render() {
    return (
      <div>
        <div className="atv-oque-intro">
          <div className='page-title'>O que é isso que eu vi no livro?</div>
          <div className="atv-text">Vocês devem ter reparado durante a leitura do livro Encanto dos Rios algumas imagens ou frases diferentes. Elas estão aí com propósito de atrair a curiosidade e trazê-los aqui para conversarmos um pouco mais sobre elas. Mas se você as não viu, sugiro que tente encontrar como um desafio.</div>
        </div>

        <div>
            <div className="page-title page-subtitle page-subtitle-small">Abaixo do planeta Saturno, no capítulo <b>Água no Cosmos</b>, está escrito “o pálido ponto azul”. Mas o que isso significa?</div>
            <div className="atv-text">O pálido ponto azul faz referência a uma foto feita pela sonda Voyager 1 de 1990, de uma distância de 6 bilhões de quilômetros da Terra.  Nesta foto há um pontinho bem pequeno que é o nosso planeta visto de muito longe, no campo superior é possível observar os anéis de Saturno. Esta foto deu origem a um dos textos mais belos e profundos produzidos, tendo o mesmo nome da foto “O pálido ponto azul” feito pelo astrônomo estadunidense Carl Sagan (1934- 1996). </div>
            <img src={AtvOqueFoto1} alt="Pálido ponto azul" className="atv-img oque-img"></img>
            <div className="atv-text">Convidamos vocês ao deleite de um trecho desta obra através do vídeo a seguir.</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/coKzTTnWCPw?start=74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div>
            <div className="page-title page-subtitle page-subtitle-small">Ao lado do desenho de uma gota de água gigante, no capítulo <b>Será que a Água acaba</b>, está escrito <i>“o universo em uma gota de água”</i>. Mas o que isso significa?</div>
            <div className="atv-text">Essa frase também foi usada por Carl Sagan em seu livro Cosmos (1980) onde descreveu o primeiro microscópio. O cientista holandes Antonie van Leeuwenhoek (1636- 1723) estava com um óculo de aumento utilizado pelos negociantes de pano para examinar a qualidade do tecido e quando apontou em um lugar diferente do habitual, como na água, ele descobriu um universo em uma gota d'água. Através desta frase nosso ilustrador desenhou alguns microrganismos que podem ser encontrados em uma gota de água dos nossos rios. Nela podemos ver tardígrados, bactérias E. coli, protozoários de água doce, cianobactérias e fungos aquáticos. A qualidade da água de um rio pode ser medida através de seus microrganismos, uma elevada quantidade de bactérias  E. coli, por exemplo, indica desequilíbrio ambiental.</div>
            <img src={AtvOqueFoto2} alt="Universo em uma gota de água" className="atv-img oque-img"></img>
        </div>

        <div>
            <div className="page-title page-subtitle page-subtitle-small">No canto direito, no capítulo “Quem vive aqui?” tem a ilustração de um Saci Pererê. Mas por que ele foi desenhado aí?</div>
            <div className="atv-text">O Saci Pererê é um personagem do folclore brasileiro. Ele é um menino de uma perna só, que muda de tamanho (pode ser pequeno como uma formiga ou ficar do tamanho de um adulto), fuma cachimbo, usa gorro vermelho, e adora fazer travessuras. Apesar de ser levado, ele é um protetor da natureza e personagem importante no fortalecimento da cultura brasileira.</div>
            <img src={AtvOqueFoto3} alt="Saci" className="atv-img oque-img"></img>
        </div>

        <div>
            <div className="page-title page-subtitle page-subtitle-small">Na página 17 há uma ilustração de uma Gnomo. O que ele faz aí?</div>
            <div className="atv-text">Gnomos são seres mitológicos considerados espíritos do elemental da terra na cultura brasileira. Isso significa que eles fazem parte da magia da floresta, vivem dentro de troncos e tocas, ajudam as flores a desabrochar e os animais a se guiar.</div>
            <img src={AtvOqueFoto4} alt="Gnomo" className="atv-img oque-img"></img>
        </div>

        <div>
            <div className="page-title page-subtitle page-subtitle-small">Ainda na página 17 há uma ilustração de um veado albino. O que ele faz aí?</div>
            <div className="atv-text">Esse animal é, na verdade, um ser mágico da cultura indígena. Seu nome é Anhangá, espírito protetor da natureza e dos animais, que assusta e afugenta os caçadores que entram na mata.</div>
            <img src={AtvOqueFoto5} alt="Veado albino" className="atv-img oque-img"></img>
        </div>

        <div>
            <div className="page-title page-subtitle page-subtitle-small">No capítulo “Quem vive aqui?” Há vários nomes diferentes que parecem trava-línguas. Mas o que essas palavras significam?</div>
            <div className="atv-text">São os nomes científicos dos seres vivos que estão na imagem ao lado de cada uma dessas palavras! Cada ser vivo é designado por um nome científico, escrito em latim. Isso serve para que as plantas e animais possam ser reconhecidos em qualquer lugar do mundo. Por exemplo, a planta da imagem abaixo é conhecida no sudeste como Costela de Adão, mas no nordeste como abacaxi-do-reino, e nos Estados Unidos como Swiss Cheese (na tradução, “queijo suíço), e ela possui diversos outros nomes em cada localidade. Entretanto, se eu disser Monstera deliciosa, imediatamente saberão de qual planta estou falando, pois o nome científico é o mesmo em qualquer lugar do mundo!!!</div>
            <img src={AtvOqueFoto6} alt="Nome cientifico" className="atv-img oque-img"></img>
            <div className="atv-text">Segue listagem de nomes científicos que aparecem no capítulo, e seus nomes populares aqui na nossa região:</div>
            <div className="atv-text"><i>Erythrina speciosa</i> - Mulungu (árvore)</div>
            <div className="atv-text"><i>Eugenia uniflora</i> - Pitangeira (árvore)</div>
            <div className="atv-text"><i>Euterpe edulis</i> - Palmito Jussara (palmeira)</div>
            <div className="atv-text"><i>Caryocar brasiliensis</i> - Pequizeiro (árvore)</div>
            <div className="atv-text"><i>Pteridófita</i> - grupo de plantas as quais estão as samambaias, avencas e cavalinhas</div>
            <div className="atv-text"><i>Rhinella schineideri</i> - Sapo Cururu (sapo)</div>
            <div className="atv-text"><i>Monstera deliciosa</i> - Costela de Adão (planta)</div>
            <div className="atv-text"><i>Schinnus molle</i> - Falso chorão (árvore)</div>
            <div className="atv-text"><i>Geophagus brasiliensis</i> - Acará Papa Terra (animal peixe)</div>
            <div className="atv-text"><i>Phrynops hilarii</i> - Cágado cinza (animal cágado)</div>
            <div className="atv-text"><i>Cecropia pchystachya</i> - Embaúba (árvore)</div>
            <div className="atv-text"><i>Carollia perspicillata</i> - Morcego de cauda curta (animal morcego)</div>
            <div className="atv-text"><i>Pseudoleistes guirahuro</i> - Soldado (animal pássaro)</div>
            <div className="atv-text"><i>Monodelphis iheringi</i> - Cuíca de três listras (animal cuíca)</div>
            <div className="atv-text"><i>Didelphis albiventris</i> - Gambá de orelha branca (animal gambá)</div>
        </div>
      </div>
    );
  }
}

export default AtvOque;