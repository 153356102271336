import React, { Component } from 'react';

import Steam from '../../assets/STEAM.png';
import './index.css';

class AtvIntro extends Component {

  render() {
    return (
      <div>
        <div className="page-title">Atividades - Introdução</div>
        <div className="atv-intro-text">O livro Encanto dos Rios, assim como as atividades propostas nesta plataforma, são focadas na aprendizagem criativa através do STEAM que  é a sigla em inglês para Science, Technology, Engineering, Art e Mathematics (Ciência, Tecnologia, Engenharia, Arte e Matemática, em português). São valorizadas o ensino da ciência através do conhecimento científico e da divulgação científica relacionado aos recursos hídricos, tecnologias nas propostas de atividades no uso de aplicativos de realidade aumentada e holograma; engenharia na aplicação dos conteúdos científicos, como no experimento de simulação do ciclo da água; a arte no processo criativo das escolhas das colorações e poesias nas canções de cada capítulo; e conhecimentos matemáticos utilizado nas medições das experiências.</div>
        <img src={Steam} alt="Ilustração do símbolo STEAM" className="atv-intro-img"></img>
        <div className="atv-intro-text">Cada capítulo tem uma proposta de brincadeira e atividade lúdica, o <b>BRINQUENCANTO</b>, possui propostas de <b>EXPERIMENTO CIENTÍFICO</b>, e uma composição musical inédita relacionado ao tema do capítulo, que chamamos de <b>CANTAENCANTO</b>.</div>
      </div>
    );
  }
}

export default AtvIntro;