import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import steamEng from '../../assets/ENGINEERING - STEAM.png';
import steamArt from '../../assets/ART - STEAM.png';
import steamScience from '../../assets/SCIENCE - STEAM.png';
import steamMath from '../../assets/MATHEMATIC - STEAM.png';
import steamTech from '../../assets/TECHNOLOGIE - STEAM.png';
import Brin1Foto1 from '../../assets/photos/cap3-foto1.JPG';
import Brin1Foto2 from '../../assets/photos/cap3-foto2.JPG';
import Brin1Foto3 from '../../assets/photos/cap3-foto3.JPG';
import Brin1Foto4 from '../../assets/photos/cap3-foto4.JPG';
import Brin1Foto5 from '../../assets/photos/cap3-foto5.JPG';
import Brin1Foto6 from '../../assets/photos/cap3-foto6.JPG';
import Brin1Foto7 from '../../assets/photos/cap3-foto7.JPG';
import Brin1Foto8 from '../../assets/photos/cap3-foto8.JPG';
import Brin1Foto9 from '../../assets/photos/cap3-foto9.JPG';
import Brin1Foto10 from '../../assets/photos/cap3-foto10.JPG';
import Brin1Foto11 from '../../assets/photos/cap3-foto11.JPG';
import AtivGif from '../../assets/photos/Ativ-Cap-3.gif';
import './index.css';

class AtvCap3 extends Component {

  render() {
    return (
      <div>
        <div className="atv-cap3-intro">
          <div className='page-title'>CAPÍTULO 3: SERÁ QUE A ÁGUA ACABA</div>
          <div className="atv-text">No capítulo 3 do livro Encanto dos Rios, foram desmistificados a expressão de que “a água vai acabar”, ressaltando que se não cuidarmos das nossas água o que acabaria seria a água potável. Discorremos sobre a poluição, doenças de veiculação hídrica e diferentes usos da água.</div>
          <div className="atv-text">Destacamos as implicações políticas, históricas e socioambientais no uso da água, assim como alternativas e desafios do mundo contemporâneo para a preservação hídrica e ambiental.</div>
        </div>

        <div>
          <div className='page-title page-subtitle'>BRINQUENCANTO: BOLHA DE SABÃO <img src={steamScience} alt="Imagem STEAM: CIÊNCIA " className="atv-ico"></img><img src={steamEng} alt="Imagem STEAM: ENGENHARIA " className="atv-ico"></img><img src={steamMath} alt="Imagem STEAM: MATEMATICA " className="atv-ico"></img></div>
          <div className="atv-text">Quem não fica encantado e hipnotizado ao observar bolhas de sabão flutuando sobre o ar? Nesta atividade propomos fazer bolhas de sabão gigante com materiais fáceis de serem encontrados, e ainda como segurar essas bolhas de sabão na mão.</div>
          <div className="atv-text"><b>Vamos precisar de:</b></div>
          <ul className="material-list">
            <li>Barbante;</li>
            <li>2 lápis;</li>
            <li>Detergente;</li>
            <li>1 prato;</li>
            <li>Glicerina líquida;</li>
            <li>Luvas de lã.</li>
          </ul>
          <img src={Brin1Foto1} alt="Brinquencanto - Materiais" className="atv-img cap3-img"></img>
          <div className="atv-text"><b>Como fazer</b></div>
          <ul className="how-to">
            <li>Corte dois pedaços de barbante com tamanho aproximadamente 10 cm.</li>
            <li>Prenda um barbante na parte superior e inferior dos dois lápis conforme a fotografia abaixo:</li>
            <img src={Brin1Foto2} alt="Barbantes entre lápis" className="atv-img cap3-img"></img>
            <li>Coloque  aproximadamente 150 ml de água no prato.</li>
            <li>Em seguida coloque aproximadamente 50 ml de detergente e 25 ml de glicerina. </li>
            <div>
              <img src={Brin1Foto3} alt="Despejando detergente" className="atv-img cap3-img"></img>
              <img src={Brin1Foto4} alt="Despejando glicerina" className="atv-img cap3-img cap3-img-col"></img>
              <img src={Brin1Foto5} alt="Misturando" className="atv-img cap3-img cap3-img-col"></img>
            </div>
            <li>Coloque o suporte montado no item 2 em cima do prato da água e retire cuidadosamente de modo que o líquido fique preso ao barbante formando uma espécie de “tela líquida”.</li>
            <div>
              <img src={Brin1Foto6} alt="Colocando suporte na mistura" className="atv-img cap3-img"></img>
              <img src={Brin1Foto7} alt="Tirando cuidadosamente" className="atv-img cap3-img cap3-img-col"></img>
              <img src={Brin1Foto8} alt="Tela líquida formada" className="atv-img cap3-img cap3-img-col"></img>
            </div>
            <li>Em seguida assopre a “tela líquida”  e formará lindas bolhas de sabão. </li>
            <img src={Brin1Foto9} alt="Criando bolhas de sabão" className="atv-img cap3-img"></img>
            <li>Se a bolha não formar coloque um pouco mais de detergente e se a bolha estourar muito rápido coloque mais glicerina que prolongará o tempo de vida da bolha. Quanto maior a temperatura ambiente e a incidência de raios solares mais rápido será a evaporação da água na bolha e menos tempo ela durará.</li>
            <div className="atv-text"><b>Para pegar a bolha de sabão na palma das mão:</b></div>
            <li>Com uma luva de lã abra suas mãos e deixe a bolha cair sobre a luva e faça um movimento vertical leve para que a bolha pule sobre sua mão.</li>
            <li>Se divirtam!</li>
            <img src={Brin1Foto10} alt="Pegando bolhas com a mão" className="atv-img cap3-img"></img>
          </ul>
        </div>

        <div>
          <div className='page-title page-subtitle'>EXPERIMENTO: ANDANDO SOBRE A ÁGUA <img src={steamScience} alt="Imagem STEAM: CIÊNCIA" className="atv-ico"></img><img src={steamTech} alt="Imagem STEAM: TECNOLOGIA" className="atv-ico"></img><img src={steamEng} alt="Imagem STEAM: ENGENHARIA" className="atv-ico"></img><img src={steamArt} alt="Imagem STEAM: ARTE" className="atv-ico"></img><img src={steamMath} alt="Imagem STEAM: MATEMATICA" className="atv-ico"></img></div>
          <div className="atv-text">Quando vamos nadar em uma piscina, rio ou cachoeira é comum vermos insetos próximos como libélulas que sobrevoam a água em busca de larvas de mosquitos para se alimentarem, mas um dos insetos mais intrigantes que é possível observar nos corpos d’água são aqueles que se deslocam sobre ela, chamados de inseto-jesus ou aranha-d’água. Esses insetos fazem parte da família Gerridae que possuem a capacidade de andar sobre a água, esse fenômeno é explicado pela tensão superficial da água que faz com que a superfície do líquido se comporte como uma membrana elástica quando o inseto está em cima. O mais legal é que  podemos reproduzir esse fenômeno em sala de aula através de um experimento simples que os estudantes podem construir seu próprio inseto-jesus.</div>
          <div className="atv-text"><b>Curiosidades:</b> Um experimento parecido foi realizado por pesquisadores  da Universidade Nacional de Seul e da Universidade de Harvard através do desenvolvimento de um robô inspirado no inseto-jesus para aplicações que auxiliam as atividades humanas como análise de rios e sistema de vigilância.</div>
          <img src={Brin1Foto11} alt="Foto do inseto pronto em cima da água" className="atv-img cap3-img"></img>
          <ul className="how-to">
            <li>Recorte o inseto e dobre nas indicações no documento.</li>
            <div>
              <Button type="primary" icon={<DownloadOutlined />} className="download-button" target="_blank" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/inseto+jesus+pequeno.pdf">Download do desenho do inseto tamanho pequeno</Button>
            </div>
            <div>
              <Button type="primary" icon={<DownloadOutlined />} className="download-button" target="_blank" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/inseto+jesus+grande.pdf">Download do desenho do inseto tamanho grande</Button>
            </div>
            <li>Monte vários insetos.</li>
            <video controls className="cap3-img">
              <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/V%C3%ADdeo+do+inseto+jesus+grande+mp4.mp4" type="video/mp4"></source>
              Seu navegador não suporta vídeos.
            </video>
            <li>Em um recipiente com água coloque o inseto sobre a água devagar observando se as patinhas estão na posição correta conforme a imagem acima.</li>
            <img src={AtivGif} alt="Foto do inseto pronto em cima da água" className="atv-img cap3-img"></img>
          </ul>
          <div className="atv-text">O uso da cartolina ou de papel cartão, que são papéis mais grossos, para a construção do inseto-jesus faz com que ele dure mais tempo em cima da água. Se nas primeiras tentativas o inseto afundar, explique que é normal os experimentos não funcionem, e que é necessário tentar várias vezes e não desistir. Devido a isso façamcolocamos vários protótipos de insetos. na mesma folha porque durando mais ou menos tempo sobre a água ele irá afundar uma vez que o inseto é de papel.</div>

          <div className="page-title page-subtitle page-subtitle-small">Conversando sobre o experimento: Andando sobre a água</div>
          <div className="atv-text">Por que você acredita que o inseto que vocês construíram não afundou? Por que ele acaba afundando com o tempo?</div>
          <div className="atv-text">Professor (a) reforce o conceito de tensão superficial, peça que observem com auxílio de uma lupa, ou mesmo próximo do experimento, como se comporta a água com a patinha do inseto na sua superfície. </div>
          <div className="atv-text">Explique que o papel absorve a água e a medida que a pata do inseto for sendo molhada, vai ficando mais pesado e irá afundar, isso explica do porque quanto mais grosso o papel mais tempo durará o experimento pois levará mais tempo para que a água enxague toda a estrutura da pata de papel.</div>
          <div className="atv-text">Caso queira aprofundar o conhecimento,  pingue uma gota de detergente para quebrar a tensão superficial para que o inseto afunde rapidamente. É interessante citar também que esse efeito causado por detergentes e produtos de limpeza podem atingir os corpos d’água através da falta de tratamento e despejo irregular, influenciando a sobrevivência desses insetos. Converse com as crianças a respeito de outros problemas que a poluição dos corpos de água podem resultar, discutam também as soluções. Esta atividade envolve a habilidade <b>EF05CI04</b> sobre a discussão de formas sustentáveis de utilização da água.</div>
        </div>

        <div>
          <div className='page-title page-subtitle'>CANTENCANTO <img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
          <div className="atv-text">Neste capítulo, vimos que a quantidade de água potável é comprometida pelo descaso do ser humano com o meio ambiente, através da poluição da água e desmatamento. É necessário preservar os rios e as nascentes, o lugar onde nasce a água, mas como fazer isso? Converse com os estudantes estimulando <i>argumentos que justifiquem a importância da cobertura vegetal para a manutenção do ciclo da água, a conservação dos solos, dos cursos de água e da qualidade do ar atmosféricos</i> (<b>EF05CI03</b>).</div>

          <div className="music-title">CUIDA DA NASCENTE</div>
          <div className="music-composition">Composição: Valter Silva</div>
          <div>
            <div>Se quiser ver um rio, fio de água corrente</div>
            <div>Cuida bem da nascente</div>
            <div>Se quiser uma flor, plante a semente</div>
            <div>Se quiser no inverno um banho bem quente</div>
            <div>Depois do almoço escovar os dentes</div>
            <div>Cuida bem da nascente que atrás vem gente</div>
            <div>Na lua cheia, nova, minguante ou crescente</div>
            <div>Caboclos d´águas, Iaras, são seres viventes</div>
            <div>Sapos, peixes e cobras chegaram na frente</div>
            <div>Todo ser que é vivo da água depende</div>
            <div>Cuida bem da nascente que atrás vem gente</div>
            <div>Se a nascente morrer, quem morre é a gente</div>
            <div>Se a água acabar o mundo fica doente</div>
            <div>Se tá nas minhas mãos, na sua certamente</div>
            <div>Juntemos, então, coração, corpo e mente</div>
            <div>Cuida bem da nascente que atrás vem gente</div>
          </div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Cuida+da+Nascente.mp3" target="_blank">Download da música</Button></div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Cuida+da+Nascente.pdf" target="_blank">Download da letra e cifra</Button></div>
        </div>
      </div>
    );
  }
}

export default AtvCap3;