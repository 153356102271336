import React, { Component } from 'react';

import './index.css';
class Agradecimentos extends Component {

  render() {
    return (
      <div>
        <div className="agrad-intro">
          <div className='page-title'>Agradecimentos</div>
          <div className="atv-text">O projeto Encanto dos Rios nasceu do sonho de alguns alunos dentro da Universidade Federal de São Carlos - campus Sorocaba. Assim como uma semente precisa de estímulos para germinar, esse sonho também precisou do apoio de muitas pessoas, instituições e organizações para se tornar realidade. Graças a isso, você está aqui, lendo o conteúdo deste site e tem a oportunidade de desfrutar do livro paradidático “Encanto dos Rios”.</div>
          <div className="atv-text">Gostaríamos de agradecer a direção da ONG GTA Jerivá, que esteve à frente na gestão e desenvolvimento do projeto, e estender nossos agradecimentos à UFSCar, especialmente, ao Professor Doutor André Cordeiro Alves dos Santos, que foi fundamental neste alicerce.</div>
          <div className="atv-text">Gostaríamos de agradecer o entusiástico apoio das prefeituras de Votorantim e Porto Feliz, em especial dos gestores educacionais Gisele Gimenes, Tiago Antonio de Araújo e Gisele Anhaia Carriel que nos receberam e dialogaram sobre educação ambiental. Adicionalmente, agradecemos ao Colégio Mônaco, de Votorantim, por nos permitir usar seu laboratório de ciências para registrar os experimentos "Microscópio Caseiro" e "Som". Ao professor de Porto Feliz, Rafael Felício da Costa, por auxiliar nas correções dos textos. Estamos profundamente gratos pela inestimável colaboração.</div>
          <div className="atv-text">Por fim, o nosso agradecimento aos professores que estão acessando e utilizando este material. O projeto “Encanto dos Rios” espera inspirar e ser útil a todos aqueles que fazem parte da perspectiva educacional.</div>
        </div>
      </div>
    );
  }
}

export default Agradecimentos;