import React, { Component } from 'react';

import './index.css';
import LivroLogo from '../../assets/encanto_logo-min.png';

class Home extends Component {

  render() {
    return (
      <div>
        <div className="logo-home-wrapper">
          <img className="livro-logo-home" src={LivroLogo} alt="encantologo"></img>
        </div>
        <div className="text-home-wrapper">
          <div className="page-title home-title">A todos que sonham.</div>
          <div className="home-text">O Encanto dos Rios foi criado com o objetivo de ser uma ferramenta útil para os educadores que buscam promover uma educação crítica e reflexiva sobre a importância da preservação dos recursos hídricos. Ele oferece à comunidade escolar materiais e propostas de atividades que abrangem uma abordagem histórica, científica, artística e regional. É um livro paradidático destinado a educadores da rede municipal dos municípios de Votorantim e Porto Feliz, que lecionam do 1º ao 5º ano do Ensino Fundamental I. Com aproximadamente 279 professores envolvidos e 9.631 alunos atendidos, o livro tem como objetivo contribuir com os planos de aula e projetos de ensino.</div>
          <div className="home-text">O livro é composto pelos capítulos: "Água nos Cosmos", "A Água", "O Ciclo da Água", "Será que a água acaba?", “A água na cidade”, "A bacia hidrográfica?", "Quem vive aqui?" e "Como cuidar da nossa água?". Além disso, ele contém mapa didático, referências históricas de Votorantim e Porto Feliz, ilustrações científicas e pop-ups interativos que se destacam nas páginas. O material também possui seis composições musicais exclusivas, cada uma dedicada a um capítulo específico, que estão disponíveis para download neste site. Além disso, há exercícios e atividades práticas que podem ser realizadas tanto em sala de aula quanto ao ar livre.</div>
          <div className="home-text">Esperamos que este livro e site possam contribuir para um ensino de qualidade, informativo e com Encanto, que promova o amor às nossas riquezas naturais e a sustentabilidade. </div>
          <div className="page-title home-title">Seguimos sonhando!</div>
        </div>
      </div>
    );
  }
}

export default Home;