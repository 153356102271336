import React, { Component } from 'react';
import './index.css';

import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

class AtvRio extends Component {

  render() {
    return (
      <div>
        <div className="atv-rio-intro">
          <div className='page-title'>O RIO DA MINHA ESCOLA</div>
          <div className="atv-text">Na escola estudamos os rios mais famosos: Rio Amazonas, Rio São Francisco, Rio Paraná, Rio Tietê... Mas e o Rio da minha cidade? Ele é tão importante quanto outros Rios, pois as minhas atitudes podem impactá-lo diretamente: seja negativamente (jogando lixo na rua e poluindo) ou positivamente (plantando árvores nativas no seu entorno).</div>
          <div className="atv-text">Pensando nisso, desenvolvemos essa atividade de apoio. Como vocês já sabem, os córregos (rios pequenininhos) se unem e formam o rio principal do município. Cada escola tem um mapa mostrando onde está localizado o córrego mais próximo, suas nascentes e o nome oficial.</div>
          <div className="atv-text">Lembrando que: o nome oficial não necessariamente é o nome pelo qual ele é (re)conhecido. Vamos aproveitar e conversar sobre pertencimento regional?</div>
        </div>

        <div>
            <div className='page-title page-subtitle'>PROPOSTA DE ATIVIDADE</div>
            <div className="atv-text">Vamos explorar os nossos rios! Para isso, partiremos rumo ao rio mais próximo de nós: o rio próximo da nossa escola. Animados? Preparem-se para embarcar nessa emocionante aventura!</div>
            <div className="atv-text atv-rio-paragraph">Ao final dessa página, busque o nome da sua escola e façam o download das atividades descritas a seguir.</div>
            <div className="atv-text">Vamos dar uma espiada no mapa. Ele traz o nome da nossa escola. Conseguem encontrá-la? Imprimam o <b>mapa</b> e observem com atenção! Ele mostra a região ao redor da escola. Vamos observar as ruas, os telhados e os comércios próximos. Quem sabe reconhecemos alguns lugares que já visitamos!?</div>

            <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/13+PROFESSOR+CANDIDO+DOS+SANTOS.jpeg" alt="Escola de exemplo" className="atv-img atv-big-img"></img>
            <div className="atv-text atv-rio-label atv-rio-paragraph">Exemplo de Mapa - Escola Prof. Candido dos Santos.</div>

            <div className="atv-text">Agora, vamos buscar pelo rio ou córrego. Ele é representado por um traço azul. Será que está pertinho da nossa escola? Existe uma floresta ao redor? E será que ele tem um nome oficial? Seria bem legal descobrir como chamamos aquele adorável riacho que aparece no mapa. Ele é especial e merece um nome!</div>
            <div className="atv-text atv-rio-paragraph">Se não souberem o nome oficial, sem problemas! Podemos perguntar aos nossos pais ou vizinhos como eles o chamam. E se ele ainda não tiver um nome, que tal darmos um nome para ele? Vamos soltar a imaginação e sugerir nomes lindos e divertidos!</div>
        </div>

        <div>
            <div className="atv-text">Após estudarmos o rio perto da escola e sua paisagem, que tal elaborarmos o nosso próprio mapa? Para isso imprimam a <b>ilustração do mapa do rio</b> e o <b>documento para recortar.</b></div>

            <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/13.png" alt="Ilustração de exemplo" className="atv-img atv-big-img"></img>
            <div className="atv-text atv-rio-label atv-rio-paragraph">Exemplo de Ilustração do Mapa do Rio - Escola Prof. Candido dos Santos.</div>

            <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Rio+-+Para+recortar+e+colar+no+mapa+do+Rio+perto+da+sua+escola.jpeg" alt="Para recortar" className="atv-img rio-img"></img>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Rio+-+Para+recortar+e+colar+no+mapa+do+Rio+perto+da+sua+escola.jpeg" target="_blank">Download do documento para recortar</Button></div>

            <div className="atv-text">O documento para recortar traz a ilustração da escola, casinhas e árvores que podem ser recortadas e coladas na ilustração do mapa. Também podemos adicionar nossas próprias ilustrações como desenhos de ruas, carros, motos, peixinhos, pássaros e pessoas. Usem criatividade e mãos à obra!</div>

            <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Rio+-+Exemplo.jpeg" alt="Exemplo pronto" className="atv-img atv-big-img"></img>
            <div className="atv-text atv-rio-label atv-rio-paragraph">Exemplo de atividade realizada - Escola Prof. Candido dos Santos.</div>
        </div>

        <div>
            <div className='page-title page-subtitle'>Mapa e Ilustração das escolas de Porto Feliz</div>
            <div className="atv-text">Escola Zilda Tomé de Moraes</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/1+ZILDA+TOM%C3%89+DE+MORAES.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/1.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Vereador Carlos Alberto de Oliveira</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/2+VEREADOR+CARLOS+ALBERTO+DE+OLIVEIRA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/2.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Vilma Fernandes Antônio</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/3+PROFESSORA+VILMA+FERNANDES+ANT%C3%94NIO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/3.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Luiza Carvalho Pires</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/4+PROFESSORA+LUIZA+CARVALHO+PIRES.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/4.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Domingos de Marco</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/5+PROFESSOR+DOMINGOS+DE+MARCO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/5.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Antônio de Pádua Martins de Melo</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/6+PROFESSOR+ANT%C3%94NIO+DE+PADUA+MARTINS+DE+MELO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/6.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Coronel Esmédio</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/7+CORONEL+ESM%C3%89DIO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/7.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Aurora Machado Guimarães</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/8+AURORA+MACHADO+GUIMAR%C3%83ES.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/8.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Maria Aparecida Fernandes Leite</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/9+MARIA+APARECIDA+FERNANDES+LEITE.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/9.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Nadyr Marchi dos Santos</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/10+NADYR+MARCHI+DOS+SANTOS.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Porto+Feliz/10.png" target="_blank">Download da ilustração</Button>
            </div>
        </div>

        <div>
            <div className='page-title page-subtitle'>Mapa e Ilustração das escolas de Votorantim</div>

            <div className="atv-text">Escola Eugenia Maria da Silveira</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/1+EUGENIA+MARIA+DA+SILVEIRA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/1.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Antonio Marciano</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/2+ANTONIO+MARCIANO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/2 .png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Aurora Fontes</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/3+AURORA+FONTES.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/3.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Gerson Soares de Arruda</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/4+GERSON+SOARES+DE+ARRUDA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/4.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Helena Pereira de Moraes</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/5+HELENA+PEREIRA+DE+MORAES.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/5 .png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Izabel Ferreira Coelho</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/6+IZABEL+FERREIRA+COELHO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/6.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola João Ferreira da Silva</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/7+JOAO+FERREIRA+DA+SILVA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/7.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Lucinda Rodrigues Pereira Ignacio</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/8+LUCINDA+RODRIGUES+PEREIRA+IGNACIO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/8.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Maria do Rosário Arcuri Oliveira Campos</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/9+MARIA+DO+ROSARIO+ARCURI+OLIVEIRA+CAMPOS.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/9.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Maria Helena de Moraes Scripillitti</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/10+MARIA+HELENA+DE+MORAES+SCRIPILLITTI.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/10.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Abimael Carlos de Campos</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/11+PROFESSOR+ABIMAEL+CARLOS+DE+CAMPOS.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/11.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Antonio Vicente Bernardi</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/12+PROFESSOR+ANTONIO+VICENTE+BERNARDI.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/12.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Candido dos Santos</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/13+PROFESSOR+CANDIDO+DOS+SANTOS.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/13.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Dides Crispim Almeida Antonio</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/14+PROFESSOR+DIDES+CRISPIM+ALMEIDA+ANTONIO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/14.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Gilberto Santos</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/15+PROFESSOR+GILBERTO+SANTOS.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/15.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Lauro Alves Lima</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/16+PROFESSOR+LAURO+ALVES+LIMA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/16.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Edith Maganini</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/17+PROFESSORA+EDITH+MAGANINI.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/17.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Oscar Bento Mariano</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/18+PROFESSOR+OSCAR+BENTO+MARIANO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/18.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Prof. Walter Rocha Camargo</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/19+PROFESSOR+WALTER+ROCHA+CAMARGO.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/19.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Betty de Souza Oliveira</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/20+PROFESSORA+BETTY+DE+SOUZA+OLIVEIRA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/20.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Celia Pieroni</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/21+PROFESSORA+CELIA+PIERONI.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/21.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Maria Luiza Jacowicz</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/22+PROFESSORA+MARIA+LUIZA+JACOWICZ.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/22.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Mercedes Santucci</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/23+PROFESSORA+MERCEDES+SANTUCCI.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/23.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Parizette Jordão Bressane</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/24+PROFESSORA+PARIZETTE+JORDAO+BRESSANE.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/24.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Profa. Patricia Maria dos Santos</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/25+PROFESSORA+PATRICIA+MARIA+DOS+SANTOS.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/25.png" target="_blank">Download da ilustração</Button>
            </div>

            <div className="atv-text">Escola Sueli da Silva Paula</div>
            <div className='buttons-wrap'>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/26+SUELI+DA+SILVA+PAULA.jpeg" target="_blank">Download do mapa</Button>
                <Button type="primary" icon={<DownloadOutlined />} className="download-button button-list" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/AtvRio+-+Votorantim/26.png" target="_blank">Download da ilustração</Button>
            </div>
        </div>
      </div>
    );
  }
}

export default AtvRio;