import React, { Component } from 'react';

import './index.css';
class Capacitacao extends Component {

  render() {
    return (
      <div>
        <div className="capacitacao-intro">
          <div className='page-title'>Formação Continuada</div>
          <div className="atv-text">A formação continuada deverá ser trabalhada com os Coordenadores de cada escola municipal, que irão transmitir os conteúdos às Professoras e Professores das escolas municipais da cidade de Votorantim e de Porto Feliz. </div>
          <div className="atv-text">Junto da entrega dos livros para os professores, os mesmos responderão a um questionário físico pré-utilização do material, que será escaneado pelos Coordenadores e enviado para o e-mail <a href="mailto:projetoencantodosrios@gmail.com">projetoencantodosrios@gmail.com</a>.</div>
          <div className="atv-text">Após a utilização do livro, os professores irão preencher o Relatório de Atividades, e mais uma vez, os Coordenadores irão encaminhar para <a href="mailto:projetoencantodosrios@gmail.com">projetoencantodosrios@gmail.com</a>.</div>
          <div className="atv-text">A entrega do questionário e do preenchimento do Relatório de Atividades irá auxiliar sobre o alcance do material e se os objetivos da equipe foram satisfatórios.</div>
          <div className="atv-text">A formação continuada será composta da seguinte maneira:</div>
          <div className="atv-text">Haverá conceituação de conteúdos científicos contidos no livro e explicações gerais sobre os recursos hídricos, a importância das nascentes e da floresta na produção de água e apresentação das populações tradicionais e animais da nossa bacia. Abordará a apresentação e identificação dos principais rios e córregos da região. Apresentará as atividades práticas interdisciplinares contidas no site e orientará suas abordagens e utilizações. </div>
          <div className="atv-text">Então, haverá um momento aberto para bate-papo e resolução de eventuais dúvidas.</div>
        </div>
      </div>
    );
  }
}

export default Capacitacao;