import { Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import Quem from './pages/Quem';
import ProfessorForm from './pages/ProfessorForm';
import AtvIntro from './pages/AtvIntro';
import AtvCap1 from './pages/AtvCap1';
import AtvCap2 from './pages/AtvCap2';
import AtvCap3 from './pages/AtvCap3';
import AtvCap4 from './pages/AtvCap4';
import AtvCap5 from './pages/AtvCap5';
import AtvCap6 from './pages/AtvCap6';
import AtvRio from './pages/AtvRio';
import AtvOque from './pages/AtvOque';
import AtvRef from './pages/AtvRef';
import Capacitacao from './pages/Capacitacao';
import CapacPortoFeliz from './pages/CapacPortoFeliz';
import CapacVotorantim from './pages/CapacVotorantim';
import Making from './pages/Making';
import Agradecimentos from './pages/Agradecimentos';

import './App.css';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index exact element={<Home />}></Route>
          <Route path="/quem" element={<Quem />}></Route>
          <Route path="/profLogin" element={<ProfessorForm />}></Route>
          <Route path="/atividadesIntroducao" element={<AtvIntro />}></Route>
          <Route path="/atividadesCap1" element={<AtvCap1 />}></Route>
          <Route path="/atividadesCap2" element={<AtvCap2 />}></Route>
          <Route path="/atividadesCap3" element={<AtvCap3 />}></Route>
          <Route path="/atividadesCap4" element={<AtvCap4 />}></Route>
          <Route path="/atividadesCap5" element={<AtvCap5 />}></Route>
          <Route path="/atividadesCap6" element={<AtvCap6 />}></Route>
          <Route path="/atividadesRio" element={<AtvRio />}></Route>
          <Route path="/atividadesOque" element={<AtvOque />}></Route>
          <Route path="/atividadesRef" element={<AtvRef />}></Route>
          <Route path="/capacitacao" element={<Capacitacao />}></Route>
          <Route path="/capacitacaoPortoFeliz" element={<CapacPortoFeliz />}></Route>
          <Route path="/capacitacaoVotorantim" element={<CapacVotorantim />}></Route>
          <Route path="/makingOff" element={<Making />}></Route>
          <Route path="/agradecimentos" element={<Agradecimentos />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
