import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import steamEng from '../../assets/ENGINEERING - STEAM.png';
import steamArt from '../../assets/ART - STEAM.png';
import steamScience from '../../assets/SCIENCE - STEAM.png';
import steamMath from '../../assets/MATHEMATIC - STEAM.png';
import steamTech from '../../assets/TECHNOLOGIE - STEAM.png';
import Exp1Foto1 from '../../assets/photos/cap1-foto1.JPG';
import Exp1Foto2 from '../../assets/photos/cap1-foto2.JPG';
import Exp1Foto3 from '../../assets/photos/cap1-foto3.png';
import Exp1Foto4 from '../../assets/photos/cap1-foto4.JPG';
import Exp1Foto5 from '../../assets/photos/cap1-foto5.JPG';
import Stellarium from '../../assets/photos/stellarium.jpg';
import './index.css';

class AtvCap1 extends Component {

  render() {
    return (
      <div>
        <div className="atv-cap1-intro">
          <div className='page-title'>CAPÍTULO 1: ÁGUA NO COSMOS</div>
          <div className="atv-text">O primeiro capítulo intitulado Água no cosmos explora através do questionamento se existe água fora do planeta terra, os principais mistérios e curiosidades do Cosmos, instigando os alunos ao questionamento, à formulação de hipóteses científicas e a contemplação do céu noturno.</div>
          <div className="atv-text">Os conteúdos trabalhados neste capítulo foram construídos com base nas habilidades específicas da área de ciências do ensino fundamental nos anos iniciais e também nas questões das olimpíadas brasileiras de astronomia e astronáutica (OBA) fase I, de forma a entusiasmar os estudantes aos conhecimentos astronômicos e as/os professoras/es e gestão escolar na participação do OBA.</div>
        </div>
        <div>
          <div className='page-title page-subtitle'>BRINQUENCANTO: DENTRO DO FOGUETE <img src={steamEng} alt="Imagem STEAM ENGENHARIA" className="atv-ico"></img><img src={steamArt} alt="Imagem STEAM ARTE" className="atv-ico"></img></div>
          <div className="atv-text">O capítulo “Água no Cosmos” explora uma viagem espacial para o estímulo da imaginação e curiosidades sobre a astronomia. Nas primeiras páginas é possível ver o caminho traçado pelo foguete que viaja em busca da presença da água nos corpos celestes.</div>
          <div className="atv-text">E se cada criança construísse seu próprio foguete e entrasse no livro para fazer a viagem descrita? Para entrar nessa brincadeira desenvolvemos a ilustração de um foguete para download que permite inserir a foto da criança na janelinha do foguete.</div>

          <Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Dentro+do+foguete.pdf" target="_blank">Download do foguete para a criança pintar, recortar e brincar</Button>

          <div className="atv-text atv-cap1-text-important"><b>3..2..1… DECOLAR!!!!</b></div>
          <div className="atv-text">Vamos decolar nosso foguete?</div>
          <img src={Exp1Foto5} alt="Imagem ilustrativa" className="atv-img cap1-img"></img>
        </div>

        <div>
          <div className='page-title page-subtitle'>EXPERIMENTO 1: HOLOGRAMA DO PLANETA TERRA <img src={steamScience} alt="Imagem STEAM CIÊNCIA" className="atv-ico"></img><img src={steamTech} alt="Imagem STEAM TECNOLOGIA" className="atv-ico"></img><img src={steamEng} alt="Imagem STEAM ENGENHARIA" className="atv-ico"></img><img src={steamMath} alt="Imagem STEAM MATEMATICA" className="atv-ico"></img></div>
          <div className="atv-text">“A Terra é azul!”, foi a frase dita pelo soviético Iuri Gagarin, o primeiro ser humano a viajar no espaço, em 1961 e, de lá, ter a visão de como é a nossa casa: o planeta Terra - esférico e... azul. Isso acontece porque aproximadamente 70% da superfície terrestre é composta por água. Então será que o Planeta Terra não deveria se chamar Planeta Água? Vamos também observar o nosso planeta?</div>
          <div className="atv-text">Nosso primeiro experimento envolve a construção de um holograma que propõe a reutilização de objetos do cotidiano como o papel de plástico transparente e a observação de passagem de luz para visualizar a imagem do planeta terra em três dimensões.</div>
          <video controls className="cap1-img">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Cap+1+-+V%C3%ADdeo+do+experimento+(Experimento+1).mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>
          <div>
            <div className="atv-text"><b>Materiais utilizados</b></div>
            <ul className="material-list">
              <li>Folha de plástico transparente reutilizado (como embalagem de bolo, de brinquedo, etc);</li>
              <li>Foto da criança;</li>
              <li>Régua;</li>
              <li>Tesoura sem ponta;</li>
              <li>Fita adesiva transparente;</li>
              <li>Caneta;</li>
              <li><i>Smartphone</i></li>
            </ul>
            <div className="atv-text"><b>Como fazer:</b></div>
            <ul className="how-to">
              <li>Recortar 4 formas da imagem a seguir com as seguintes medidas: 6 centímetros de largura na parte inferior, 1 centímetro na parte superior por 3,5 centímetros de altura.</li>
              <img src={Exp1Foto1} alt="Foto 1" className="atv-img cap1-img"></img>
              <li>Após recortar as quatro  partes, juntá-las conforme a imagem a seguir.</li>
              <img src={Exp1Foto2} alt="Foto 2" className="atv-img cap1-img"></img>
              <li>Baixar ou abrir o vídeo a seguir no seu smartphone (clique nos três pontinhos no canto direito do vídeo).</li>
              <Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Cap+1+-+V%C3%ADdeo+para+holograma+em+reverso+(Experimento+1).mp4" target="_blank">Download do vídeo</Button>
              <li>Colocar a estrutura montada com o papel de plástico em cima do celular conforme a imagem a seguir e observar o resultado. </li>
              <div>
                <img src={Exp1Foto4} alt="Foto 3" className="atv-img cap1-img"></img>
                <img src={Exp1Foto3} alt="Foto 4" className="atv-img cap1-img cap1-img-col"></img>
              </div>
            </ul>
            <div className="atv-text"><b>Observação:</b> com as luzes apagadas a imagem do holograma formado fica mais visível e colorida.</div>

            <div className="atv-text">Vídeo Holograma do Planeta Terra</div>
            <video controls className="cap1-img">
              <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Cap+1+-+Holograma+do+planeta+terra+v%C3%ADdeo+do+planeta+terra.mp4" type="video/mp4"></source>
              Seu navegador não suporta vídeos.
            </video>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ativ+-+Cap+1+-+Holograma+do+planeta+terra+v%C3%ADdeo+do+planeta+terra.mp4" target="_blank">Download do vídeo</Button></div>
            <div className="atv-text">Se você escrever “Holograma em 3D” no YouTube, aparecem modelos que podem ser utilizados para essa atividade!</div>

            <div className="page-title page-subtitle page-subtitle-small">Conversando sobre o experimento: holograma do Planeta Terra</div>
            <div className="atv-text">Olhem nosso lar, a Terra, observem suas cores e sua forma. Além do azul que representa a água, quais as outras cores que aparecem e o que elas representam? </div>
            <div className="atv-text">Converse com seus alunos sobre a formação do holograma que ocorreu através da passagem de luz nos papéis de plásticos transparentes que formou uma imagem em três dimensões, envolvendo a habilidade <b>EF03CI02</b>, da BNCC. Discorra também sobre as principais características observáveis do planeta Terra através do holograma, como o seu formato e suas cores; o azul representa os oceanos, o verde e marrom representam os continentes, o branco representa os pólos e as nuvens, que iremos abordar com mais detalhes nos próximos capítulos, mas que já podemos mencionar sua composição. Essas discussões envolvem a habilidade <b>EF03Cl07</b> competência 2 do ensino fundamental, que é o exercício da curiosidade intelectual através da abordagem científica, incluindo a reflexão, imaginação e formulação de hipóteses.</div>
          </div>

          <div>
            <div className='page-title page-subtitle'>CANTENCANTO <img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
            <div className="atv-text">A Terra é nosso lar, e único lugar que conhecemos, até hoje, que há presença de vida. O som dos seres vivos estão presentes em cada canto da planeta: na água podemos ouvir golfinhos, baleias; na terra o guizo da cascavel, <b>aulido</b> de lobos-guarás; nos céus lindas melodias de sabiá-laranjeira e uirapuru. São tantas espécies sonoras que compartilham conosco o mesmo planeta que não conseguiríamos contar todas aqui.  Ouçam a canção “Água” e identifiquem os sons dos seres vivos que aparecem no meio da música.</div>
            <div className="music-title">ÁGUA</div>
            <div className="music-composition">Composição: Valter Silva</div>
            <div>
              <div>Água, água</div>
              <div>Fonte que a vida criou</div>
              <div>Água, água</div>
              <div>Que corre em busca do amor</div>
              <div>Plantas e bichos</div>
              <div>Seres fantásticos</div>
              <div>O olho que nunca fechou</div>
              <div>Calmaria do tempo</div>
              <div>Orvalho, relento</div>
              <div>Canção que o mundo afagou</div>
              <div className="music-refrao">Refrão</div>
              <div>Salvem a terra</div>
              <div>Acabem com a guerra</div>
              <div>Não quero ser parte da dor</div>
              <div>Prefiro o silêncio</div>
              <div>De um rio correndo</div>
              <div>Prefiro regar uma flor</div>
              <div className="music-refrao">Refrão</div>
              <div>A mão que afaga</div>
              <div>A mão que apaga</div>
              <div>A mão que sustenta o amor</div>
              <div>Mãe terra pedindo</div>
              <div>O vento partindo</div>
              <div>E a vida brotando a cor</div>
              <div className="music-refrao">Refrão</div>
            </div>
            <div>
              <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81gua.mp3" target="_blank">Download da música</Button></div>
              <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81gua+.pdf" target="_blank">Download da letra e cifra</Button></div>
            </div>
            <div className="atv-text">Os sons de animais que aparecem na música são:</div>
            <ul className="material-list">
              <li>Baleia Jubarte</li>
              <li>Sapo</li>
              <li>Grilo</li>
            </ul>
          </div>

          <div>
            <div className='page-title page-subtitle'>OBSERVANDO O CÉU <img src={steamScience} alt="Imagem STEAM CIÊNCIA" className="atv-ico"></img><img src={steamTech} alt="Imagem STEAM TECNOLOGIA" className="atv-ico"></img></div>
            <div className="atv-text">Quando falamos de universo nos fascinamos com a sua imensidão e sobre os conhecimentos construídos pela humanidade ao longo da história da ciência que foram passadas de geração em geração. Ao olharmos para o céu vemos pontos brilhantes que, quando não possuímos formação na área, não sabemos identificar qual corpo celeste é, mas que estão lá iluminando nosso céu noturno. Incentivar os alunos a olhar para o céu e admirar esses pontos brilhantes é levá-los ao desenvolvimento da sensibilidade da beleza dos mistérios da natureza.</div>
            <div className="atv-text">Como incentivar que nossos alunos e alunas observem o céu noturno se as aulas são no período vespertino, ou ainda como desenvolver essa autonomia na criança sendo que dificilmente acompanhamos e orientamos essa observação?</div>
            <div className="atv-text">O uso de celulares smartphones e aplicativos está presente no cotidiano nas casas brasileiras e podem ser aliados à educação e ao incentivo da observação do céu noturno, um deles se chama <b>STELLARIUM</b>. Trata-se de um software, bastante utilizado por astrônomos amadores, que permite visualizar um planetário virtual tanto no celular como também no computador. Este aplicativo desenha o céu a partir da localização do observador. Ele mostra as posições das estrelas, constelações e planetas em tempo real auxiliando na identificação do corpo celeste.</div>
            <img src={Stellarium} alt="Imagem do aplicativo" className="atv-img"></img>
          </div>
        </div>
      </div>
    );
  }
}

export default AtvCap1;