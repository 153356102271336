import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import Brin1Foto1 from '../../assets/photos/cap5-foto1.jpeg';
import Exp1Foto1 from '../../assets/photos/cap5-foto2.JPG';
import Exp1Foto2 from '../../assets/photos/cap5-foto3.JPG';
import Exp1Foto3 from '../../assets/photos/cap5-foto4.JPG';
import Exp1Foto4 from '../../assets/photos/cap5-foto5.jpg';
import Exp1Foto5 from '../../assets/photos/cap5-foto6.jpg';
import steamEng from '../../assets/ENGINEERING - STEAM.png';
import steamArt from '../../assets/ART - STEAM.png';
import steamScience from '../../assets/SCIENCE - STEAM.png';
import steamMath from '../../assets/MATHEMATIC - STEAM.png';
import steamTech from '../../assets/TECHNOLOGIE - STEAM.png';
import './index.css';

class AtvCap5 extends Component {

  render() {
    return (
      <div>
        <div className="atv-cap5-intro">
          <div className='page-title'>CAPÍTULO 5: QUEM VIVE AQUI?</div>
          <div className="atv-text">O capítulo 5 apresenta por meio de recursos imagéticos a diversidade de populações tradicionais brasileiras que dependem diretamente dos recursos naturais, uma vez que faz parte da sua cultura, tradição e sabedoria. São apresentadas também a riqueza da fauna através do diálogo de algumas espécies nativas que vão conduzindo os leitores e leitoras ao universo lúdico e apresentando as principais características dos animais citados.</div>
          <div className="atv-text">Neste capítulo são valorizados a diversidade de saberes e vivências culturais, a promoção da consciência socioambiental, o acolhimento e valorização da diversidade da vida.</div>
        </div>

        <div>
          <div className='page-title page-subtitle'>BRINQUENCANTO: JOGO SONORO <img src={steamScience} alt="CIENCIA" className="atv-ico"></img><img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
          <div className="atv-text">Vocês já pararam para ouvir o som ao nosso redor? Já repararam que sempre há algo para escutar, como o canto dos pássaros, latidos de cachorros, chuva caindo no telhado, carros passando na rua, alguém cantando… O mundo é dinâmico, cheio de sons, que podem ter diferentes origem, como os sons de animais, chamado de biofonia (bio significa vida e fonia som); de construções e invenções humanas como carros e fábricas que são chamados de antropofonia (antropo para ser-humano e fonia som) e do vento, chuva e trovão que é chamado de geofonia (geo significa terra e fonia som). Neste <span className="text-highlight">cantaencanto</span> vamos despertar a curiosidade e a sensibilidade auditiva do universo sonoro da nossa região.</div>
          <div className="atv-text">Siga as instruções para a nossa brincadeira sonora: </div>
          <ul className="how-to">
            <li>Imprima o mapa didático a seguir com as imagens de alguns dos animais distribuídos no mapa didático e entregue para as crianças. </li>
            <img src={Brin1Foto1} alt="Mapa didático" className="atv-img cap5-img"></img>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/MAPA+DID%C3%81TICO+PARA+AS+PROFESSORAS+IMPRIMIREM.png" target="_blank">Download do mapa didático</Button></div>
            <li>Baixe os áudios a seguir. As crianças deverão tentar adivinhar quem produziu o som de cada áudio e relacioná-los com as imagens. Neles contêm o som de animais (chamado de biofonia), de fábricas e igrejas (chamado de antropofonia), de queda d’água (chamado de geofonia).</li>

            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/TABELA+DO+SOM.pdf" target="_blank">Download da tabela de sons</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+01+-+Gar%C3%A7a+Branca.mp3" target="_blank">Download do aúdio 1</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+02+-+Tucano.mp3" target="_blank">Download do aúdio 2</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+03+-+Lobo+Guar%C3%A1.mp3" target="_blank">Download do aúdio 3</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+04+-+Capivara.mp3" target="_blank">Download do aúdio 4</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+06+-+Urutau.mp3" target="_blank">Download do aúdio 6</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+07+-+Arara.mp3" target="_blank">Download do aúdio 7</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+08+-+Queda+d_%C3%A1gua.wav" target="_blank">Download do aúdio 8</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+09-+Rio.mpeg" target="_blank">Download do aúdio 9</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+10-+Sinos+de+Igreja.mpeg" target="_blank">Download do aúdio 10</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+11+-+Maquin%C3%A1rio+f%C3%A1brica.mp3" target="_blank">Download do aúdio 11</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+12-+Tempo+de+Brincar+_+Jongo+pra+Sao+Benedito.mp3" target="_blank">Download do aúdio 12</Button></div>
            <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/%C3%81udio+13+-+Sapo+Cururu.mp3" target="_blank">Download do aúdio 13</Button></div>

            <li>A medida que cada som seja tocado e relacionado com os animais, igrejas, fábricas ou queda de água, as crianças deverão colorir indicar no mapa didático. </li>
          </ul>
          <div className="atv-text">No áudio da capivara (Áudio n° 4) é possível detectar um som mais grave (que parece de latido de cachorro) e outro mais agudo (que parece som de passarinho). Ambos são da capivara, o mais grave possivelmente é da mãe, e o agudo do seu filhote.</div>
        </div>

        <div>
          <div className='page-title page-subtitle page-subtitle-small'>VAMOS BRINCAR COM UM MORCEGO DE PAPEL?</div>
          <div className="atv-text">Ei, você sabia que alguns animais podem saber o que está na sua frente através do som? É incrível! Essa habilidade especial é chamada de ecolocalização. Quando esses animais, como os morcegos, emitem um som, ele se propaga pelo ar até atingir um objeto. O som então volta, ou ecoa, e retorna aos ouvidos do animal. Ao ouvir os ecos, o animal consegue perceber a que distância o objeto está e até como ele se parece!</div>
          <div className="atv-text">É muito legal, não é? E o engraçado é que nós, humanos, não conseguimos nem mesmo ouvir os sons que esses animais usam para ecolocalização. Mas podemos aprender muito observando e estudando esses animais incríveis.</div>
          <div className="atv-text">Vamos construir um morcego de papel para poder brincar e observar?</div>

          <video controls className="cap5-img">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Montagem+do+morcego.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/morcego+montagem+(1).pdf" target="_blank">Download do documento do morcego</Button></div>
        </div>

        <div>
          <div className='page-title page-subtitle'>EXPERIMENTO: O SOM <img src={steamScience} alt="CIENCIA" className="atv-ico"></img><img src={steamTech} alt="TECNOLOGIA" className="atv-ico"></img> <img src={steamEng} alt="ENGENHARIA" className="atv-ico"></img><img src={steamMath} alt="MATEMATICA" className="atv-ico"></img></div>          
          <div className="atv-text">O som é usado no nosso planeta para a comunicação, expressão artística e também para a localização, como vimos no BRINQUENCANDO anterior. Os seres vivos, o vento e a água produzem sons e são os músicos desta orquestra ecológica. Fechem os olhos e ouça, a todo instante tem um som sendo produzido, podendo ser de pessoas, animais, máquinas, ventos e etc. Pena que não podemos ver o som… mas será mesmo? E se construíssemos uma máquina que produz a imagem do som?</div>
          <div className="atv-text">Vamos precisar de:</div>
          <ul className="material-list">
            <li>1 lata de alumínio;</li>
            <li>1 CD velho;</li>
            <li>1 balão de plástico;</li>
            <li>Fita adesiva;</li>
            <li>Fita dupla-face;</li>
            <li>Tesoura;</li>
            <li>Papel cartão;</li>
            <li>Abridor de lata/faca/tesoura;</li>
            <li>Laser.</li>
          </ul>
          <div className="atv-text">Como esse experimento requer uso de um abridor de lata que pode ser perigoso para o manuseio das crianças, ele poderá ser realizado pela/pelo professora/professor previamente e apresentado aos estudantes durante a aula.</div>
          <div className="atv-text">Como fazer:</div>
          <ul className="how-to">
            <li>Com o abridor de lata/faca/tesoura, retirar, com cuidado, o fundo e a boca da lata de alumínio.</li>
            <img src={Exp1Foto1} alt="Lata de alumínio sem fundo" className="atv-img cap5-img"></img>
            <li>Com a tesoura recortar a ponta do balão de plástico e prender no fundo da lata de alumínio com auxílio da fita adesiva.</li>
            <img src={Exp1Foto2} alt="Lata de alumínio com balão preso no fundo" className="atv-img cap5-img"></img>
            <li>Recortar um quadrado pequeno do CD velho e colar no plástico da bexiga com uma fita adesiva dupla-face.</li>
            <img src={Exp1Foto3} alt="CD colado na bexiga" className="atv-img cap5-img"></img>
            <li>Enrolar o papel cartão para usar como suporte do laser; seu formato ficará como um caninho. Para fixar o laser, corte sua extremidade em V.</li>
            <img src={Exp1Foto4} alt="Papel cartão enrolado" className="atv-img cap5-img"></img>
            <li>Prender o caninho de papel cartão com o laser na lata de alumínio posicionando a luz do laser no pedaço do CD.</li>
            <img src={Exp1Foto5} alt="Experimento pronto" className="atv-img cap5-img"></img>
            <li>Apague as luzes e fale dentro da lata e observe o desenho do som.</li>
            <video controls className="cap5-img">
              <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Desenho+do+som.mp4" type="video/mp4"></source>
              Seu navegador não suporta vídeos.
            </video>
          </ul>

          <div className='page-title page-subtitle page-subtitle-small'>Conversando sobre o experimento: o som</div>
          <div className="atv-text"><b>Por que ao falarmos na máquina que construímos aparecem diferentes formas desenhadas?</b></div>
          <div className="atv-text">Para explicar como funciona o experimento peça para as crianças falarem e colocarem dois dedos (o indicador e dedo médio) sob o próprio pescoço. Nesse momento elas irão sentir <b>um leve tremor</b> que é a vibração das pregas vocais. Explique que o som é o resultado dessa vibração e que depois de ser entoado pelas pregas vocais, ocorre a vibração do ar que chegam aos nossos ouvidos. Quando falamos próximo à lata, o som da nossa voz é entoada e bate no plástico do balão. Quando o som alcança o balão, ele vibra e reflete a luz do laser que bate no pedaço do CD resultando em diferentes formas. Esta atividade explora a produção da vibração sonora e a identificação das variáveis que influenciam nesse fenômeno, contemplando a habilidade <b>EF03CI01.</b></div>
        </div>

        <div>
          <div className='page-title page-subtitle'>CANTAENCANTO <img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
          <div className="atv-text">Neste cantaencanto, te convidamos a conhecer a diversidade que existe no nosso quintal, que é o nosso bioma, nossa mata, onde vivem diferentes seres vivos. Nosso quintal é rico de encantos e cada ser vivo que nele habita é importante para manter o equilíbrio ecológico. Vamos conhecer alguns desses seres vivos?</div>

          <div className="music-title">Nosso Quintal</div>
          <div>
            <div>Eu vi um lobo, era guará </div>
            <div>Uma cuíca veio brincar </div>
            <div>A cobra d’água, sabe nadar </div>
            <div>E o gavião foi peneirar </div>
            <div>A garça branca sabe plainar </div>
            <div>O porco espinho, sabe espinhar </div>
            <div>O saruê tem uma bolsinha </div>
            <div>Pros seus filhotes carregar </div>
            <div>A capivara brinca num bando</div>
            <div>Já o quati, não quis chegar </div>
            <div>Lá no escuro ouvi o saci </div>
            <div>E uma coruja com a lua voar </div>
            <div>A cascavel tem um chocalho </div>
            <div>No fim do corpo, pra te avisar </div>
            <div>O urutau, fica quietinho </div>
            <div>Pra confundir o seu olhar </div>
            <div>Vi um calango tomando sol </div>
            <div>Já o teiú, lagartear </div>
            <div>O urubu, voa bem alto </div>
            <div>Pra sua comida avistar </div>
            <div>Tinha uma onça, bebendo água </div>
            <div>Ela era parda, linda de olhar </div>
            <div>Gato do mato, eu vi passar </div>
            <div>Eu vi bem alto um carcará </div>
            <div>Uma orquestra, eu vi tocar </div>
            <div>Era de sapos, a coaxar </div>
            <div>Um bem te vi, diz que me viu </div>
            <div>Na laranjeira um sabiá </div>
            <div>Tanta riqueza, pra se falar </div>
            <div>São tantas vidas, pra se cuidar </div>
            <div>São tantos seres nesse quintal </div>
            <div>Então não deixe isso acabar…</div>
          </div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Nosso+Quintal.mp3" target="_blank">Download da música</Button></div>
          <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Cap+5+-+Nosso+Quintal.pdf" target="_blank">Download da letra e cifra</Button></div>
        </div>
      </div>
    );
  }
}

export default AtvCap5;