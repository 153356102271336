import React, { Component } from 'react';

import './index.css';
class CapacitacaoVotorantim extends Component {

  render() {
    return (
      <div>
        <div className="capacitacao-intro">
          <div className='page-title'>Fotos da Capacitação junto aos Coordenadores e Diretores de Votorantim</div>

          <div className="atv-text">Livros paradidáticos “Encanto dos Rios” organizados para serem entregues às escolas municipais de Votorantim-SP.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/1.jpeg" alt="Foto 1" className="atv-img cap-img"></img>

          <div className="atv-text">Momento da fala da Presidente da ONG GTA Jerivá, Lucélia Ferrari.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/2.jpeg" alt="Foto 2" className="atv-img cap-img"></img>

          <div className="atv-text">Fala do secretário de Meio Ambiente de Votorantim, Carlos Alberto Leite “Mineiro”.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/3.jpeg" alt="Foto 3" className="atv-img cap-img"></img>

          <div className="atv-text">Abertura da formação com a fala da Deise Gonçalves Mota, diretora do departamento pedagógico da Secretaria de Educação.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/4.jpeg" alt="Foto 4" className="atv-img cap-img"></img>

          <div className="atv-text">Momento de entrega do livro para a Secretaria de Educação de Votorantim-SP. Da esquerda para direita, Angélica Felício, Samara Rached e Deise Mota.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/5.jpeg" alt="Foto 5" className="atv-img cap-img"></img>

          <div className="atv-text">Alguns livros sendo autografados.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/6.jpeg" alt="Foto 6" className="atv-img cap-img"></img>

          <div className="atv-text">Da direita para a esquerda: Davi Santos Genesi (Vice-presidente GTA Jerivá), Felipe Zamboni (Auxiliar no Projeto Encanto dos Rios) e Gustavo Rangel (Tesoureiro GTA Jerivá).</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/7.jpeg" alt="Foto 7" className="atv-img cap-img"></img>

          <div className="atv-text">Coordenadora representante de escola municipal de Votorantim recebendo os livros paradidáticos Encanto dos Rios para levar à sua escola.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/8.jpeg" alt="Foto 8" className="atv-img cap-img"></img>

          <div className="atv-text">As representantes das escolas municipais de Votorantim recebendo os livros paradidáticos Encanto dos Rios para levar às suas escolas.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/9.jpeg" alt="Foto 9" className="atv-img cap-img"></img>

          <div className="atv-text">A Capacitadora Samara Rached apresentando o conteúdo do livro para a gestão das escolas de Votorantim.</div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/10.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">Momento de leitura e bate-papo sobre as temáticas científicas abordadas no conteúdo do livro, por Samara Rached.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/11.jpeg" alt="Foto 11" className="atv-img cap-img"></img>

          <div className="atv-text">A Capacitadora Angélica Felício da Costa apresentando o conteúdo do site Encanto dos Rios.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/12.jpeg" alt="Foto 12" className="atv-img cap-img"></img>

          <div className="atv-text">Fala de Angélica Felício da Costa.</div>
          <video controls className="cap-img cap-video">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/13.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>

          <div className="atv-text">A escritora Angélica Felício da Costa realizando a atividade do site "Conexão da vida" com a equipe das escolas.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/14.jpeg" alt="Foto 14" className="atv-img cap-img"></img>

          <div className="atv-text">Fala do Capacitador Rafael Ocanha sobre os rios regionais de Votorantim.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/15.jpeg" alt="Foto 15" className="atv-img cap-img"></img>

          <div className="atv-text">Coordenadores e Diretores acompanhando junto ao livro a dinâmica da Formação Continuada.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/16.jpeg" alt="Foto 16" className="atv-img cap-img"></img>

          <div className="atv-text">Coordenadores e Diretores acompanhando junto ao livro a dinâmica da Formação Continuada.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/17.jpeg" alt="Foto 17" className="atv-img cap-img"></img>

          <div className="atv-text">Os Capacitadores (da esquerda para direita) Angélica Felício, Rafael Ocanha e Samara Rached.</div>
          <img src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Capac-Votorantim/18.jpeg" alt="Foto 18" className="atv-img cap-img"></img>
        </div>
      </div>
    );
  }
}

export default CapacitacaoVotorantim;