import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import steamEng from '../../assets/ENGINEERING - STEAM.png';
import steamArt from '../../assets/ART - STEAM.png';
import steamScience from '../../assets/SCIENCE - STEAM.png';
import steamMath from '../../assets/MATHEMATIC - STEAM.png';
import steamTech from '../../assets/TECHNOLOGIE - STEAM.png';
import Brin1Foto1 from '../../assets/photos/cap2-foto1.JPG';
import Brin1Foto2 from '../../assets/photos/cap2-foto2.JPG';
import Brin1Foto3 from '../../assets/photos/cap2-foto3.JPG';
import Brin1Foto4 from '../../assets/photos/cap2-foto4.JPG';
import Brin1Foto5 from '../../assets/photos/cap2-foto5.JPG';
import Exp1Foto1 from '../../assets/photos/cap2-foto6.JPG';
import Exp1Foto2 from '../../assets/photos/cap2-foto7.JPG';
import Exp1Foto3 from '../../assets/photos/cap2-foto8.JPG';
import Exp1Foto4 from '../../assets/photos/cap2-foto9.JPG';
import Exp2Foto1 from '../../assets/photos/cap2-foto10.JPG';
import Exp2Foto2 from '../../assets/photos/cap2-foto11.JPG';
import Exp2Foto3 from '../../assets/photos/cap2-foto12.JPG';
import Exp2Foto4 from '../../assets/photos/cap2-foto13.JPG';
import Exp2Foto5 from '../../assets/photos/cap2-foto14.JPG';
import ExpFoto15 from '../../assets/photos/cap2-foto15.png';
import './index.css';

const AtvCap2 = () => {
  return (
    <div>
      <div className="atv-cap2-intro">
        <div className='page-title'>CAPÍTULO 2: A ÁGUA</div>
        <div className="atv-text">No capítulo 2, convidamos nossos leitores a conhecer o mundo microscópico dentro de uma gota de água, que igualmente às medições astronômicas, são de proporções de difícil compreensão. Um exemplo é a proporção molecular, não sendo possível a observação dos microscópios de luz convencionais, pois são formas tão minúsculas que a luz não chega a essa proporção. Com ajuda da imaginação, utilizando nossos super olhos capazes de ver o mundo microscópico, falamos das moléculas da água fazendo analogias com bolinhas para explicar seu comportamento. Trabalhamos no livro o exercício da curiosidade intelectual através da abordagem da própria ciência envolvendo a reflexão, análise crítica, imaginação e a criatividade para investigar a propriedade da água.</div>
      </div>

      <div>
        <div className='page-title page-subtitle'>BRINQUENCANTO: PINTURA NO GELO <img src={steamEng} alt="ILUSTRAÇÃO STEAM ENGENHARIA" className="atv-ico"></img><img src={steamArt} alt="ILUSTRAÇÃO STEAM ARTE" className="atv-ico"></img></div>
        <div className="atv-text">Já reparou como as crianças gostam de brincar com gelo? Elas ficam intrigadas com os cubos de gelo e a sensação térmica congelante na pele. E se aliarmos essa curiosidade com brincadeiras? Para isso apresentamos uma forma divertida e diferente de entretenimento com gelo através da arte e ciência.</div>
        <div className="atv-text"><b>Vamos precisar de:</b></div>
        <ul className="material-list">
          <li>Água;</li>
          <li>Sal;</li>
          <li>Corante alimentício;</li>
          <li>Bexiga de plástico, tigela de plástico ou potinho de gelo;</li>
        </ul>
        <img src={Brin1Foto1} alt="Brinquencanto - Materiais" className="atv-img cap2-img"></img>
        <div className="atv-text"><b>Como fazer</b></div>
        <ul className="how-to">
          <li>Colocar água dentro da bexiga, encha a quantidade de preferência, também pode ser feito com tigela de plástico ou mesmo porta cubinhos de gelo.</li>
          <li>Coloque água no congelador.</li>
          <li>Quando congelada, retire a bexiga ou a tigela de plástico.</li>
          <li>Colocar sal em cima do gelo.</li>
          <img src={Brin1Foto2} alt="Brinquencanto - Sal no gelo" className="atv-img cap2-img"></img>
          <li>Pingar corantes, da cor que desejar.</li>
          <img src={Brin1Foto3} alt="Brinquencanto - Corantes no gelo" className="atv-img cap2-img"></img>
          <li>Faça lindas pinturas.</li>
          <img src={Brin1Foto4} alt="Brinquencanto - Pronto" className="atv-img cap2-img"></img>
          <img src={Brin1Foto5} alt="Brinquencanto - Pronto" className="atv-img cap2-img cap2-img-col"></img>
        </ul>
      </div>

      <div>
        <div className='page-title page-subtitle'>EXPERIMENTO: MICROSCÓPIO CASEIRO <img src={steamScience} alt="ILUSTRAÇÃO STEAM: CIÊNCIA " className="atv-ico"></img><img src={steamTech} alt="ILUSTRAÇÃO STEAM: TECH " className="atv-ico"></img><img src={steamEng} alt="ILUSTRAÇÃO STEAM: ENGENHARIA " className="atv-ico"></img><img src={steamMath} alt="ILUSTRAÇÃO STEAM: MATEMATICA " className="atv-ico"></img></div>
        <div className="atv-text">Com a imaginação, o segundo capítulo ativa a supervisão microscópica que, muito além dos microscópios de luz e alguns microscópios eletrônicos, mostra para as crianças as moléculas da água fazendo analogia com bolinhas. E se pudéssemos ativar realmente nossa visão microscópica? Para isso, propomos a construção de um divertido microscópio caseiro, que não mostra as moléculas, mas pode apresentar diversidade de formas que podem estar dentro de uma gota de água.</div>
        <div className="atv-text"><b>Materiais utilizados</b></div>
        <ul className="material-list">
          <li>Laser;</li>
          <li>Uma seringa;</li>
          <li>Água de um córrego ou riacho, ou água com terra;</li>
          <li>Dois copos de mesmo tamanho;</li>
          <li>Vários livros de diferentes tamanhos.</li>
        </ul>
        <div className="atv-text"><b>Como fazer</b></div>
        <ul className="how-to">
          <li>Colocar água de um rio, poça de água depois de um dia de chuva, terra com água ou amostra de qualquer corpo d’água que achar interessante na seringa.</li>
          <li>Soltar uma gotinha de água na seringa devagar de modo a não deixar ela pingar.</li>
          <img src={Exp1Foto1} alt="Experimento - Seringa" className="atv-img cap2-img"></img>
          <li>Escolher uma parede branca lisa para projetar a imagem do laser.</li>
          <li>Apoiar a seringa invertida com uma gota suspensa sobre os dois copos.</li>
          <img src={Exp1Foto2} alt="Experimento - Seringa apoiada" className="atv-img cap2-img"></img>
          <li>Empilhe os livros de modo a ajustar uma posição para colocar o laser e sua luz  atravesse a gota de água da seringa e até a parede branca. Essa que dá mais trabalho, porque é necessário  acertar a precisão da altura.</li>
          <img src={Exp1Foto3} alt="Experimento - Laser na posição" className="atv-img cap2-img"></img>
        </ul>

        <div className="video-col">
          <img src={Exp1Foto4} alt="Imagem ilustrativa" className="atv-img cap2-img"></img>
          <video controls className="cap2-img cap2-img-col">
            <source src="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Microsc%C3%B3pio+caseiro.mp4" type="video/mp4"></source>
            Seu navegador não suporta vídeos.
          </video>
        </div>

        <div className="page-title page-subtitle page-subtitle-small">Conversando sobre o experimento: microscópio caseiro</div>
        <div className="atv-text">O que vemos na imagem projetada da parede da gota de água? Como  foi possível aumentar a imagem da gota?</div>
        <div className="atv-text">A imagem projetada na parede pode conter pedaços de plantas, larvas de insetos, protozoários de água doce e grãos de areia. Peça que os alunos realizem ilustrações das imagens e tentem identificar as formas presentes na água. Algumas formas que podem estar presentes na água listamos abaixo, contudo podem aparecer algumas de difícil identificação, neste caso usem a imaginação e a criação de hipóteses científicas.</div>
        <div className="atv-text">E como foi possível aumentar tanto o que está em uma gota de água?</div>
        <div className="atv-text">Isso é explicado pela luz do laser que ao atravessar a água, muda sua velocidade, fazendo com que a gotinha se comporte como uma lente de aumento e mudando a direção do caminho da luz conforme a imagem abaixo:</div>
        <img src={ExpFoto15} alt="Explicação experimento" className="atv-img cap2-img"></img>
        <div className="atv-text">O experimento do microscópio caseiro envolve o habilidade <b>EF02CI08</b> que envolve comparar o efeito da luz na água; também a habilidade <b>EF03CI02</b> que é experimentar e relatar o que ocorre com a passagem da luz na água.</div>
      </div>

      <div>
        <div className='page-title page-subtitle'>EXPERIMENTO: CICLO DA ÁGUA <img src={steamScience} alt="ILUSTRAÇÃO STEAM: CIÊNCIA" className="atv-ico"></img><img src={steamEng} alt="ILUSTRAÇÃO STEAM: ENGENHARIA" className="atv-ico"></img><img src={steamMath} alt="ILUSTRAÇÃO STEAM: MATEMATICA" className="atv-ico"></img></div>
        <div className="atv-text">O ciclo da água e a sua interação com os fatores abióticos como o solo e temperatura e bióticos como as plantas e animais é essencial para manter o equilíbrio do planeta terra. Este ciclo é apresentado de forma lúdica e científica no livro e também pode ser simulado em  ambiente controlado dentro de um laboratório, cozinha ou mesmo na sala de aula. Neste experimento propomos uma experiência cujo processo é lento, pode levar dois dias, dependendo da temperatura ambiente, para observação de resultados.</div>
        <div className="atv-text"><b>Materiais utilizados</b></div>
        <ul className="material-list">
          <li>62,2 grama ou 4 colheres de sopa de sal;</li>
          <li>250 ml  ou 1 copo de água;</li>
          <li>3 gotas de corante alimentício azul;</li>
          <li>Papel filme de PVC;</li>
          <li>Pedrinhas.</li>
          <li>Opcional - luminária com lâmpada ou uma lanterna (pode ser lanterna do celular também);</li>
          <li>Dois recipientes transparentes de tamanhos diferentes.</li>
        </ul>
        <div className="atv-text"><b>Como fazer</b></div>
        <ul className="how-to">
          <li>Coloque o recipiente menor no centro do recipiente maior.</li>
          <img src={Exp2Foto1} alt="Experimento - Recipientes" className="atv-img cap2-img"></img>
          <li>Coloque a água no recipiente maior.</li>
          <li>Coloque o corante alimentício e sal na água.</li>
          <li>Tampe o recipiente maior com o papel filme de modo que o papel fique esticado.</li>
          <img src={Exp2Foto2} alt="Experimento - Recipientes com água" className="atv-img cap2-img"></img>
          <li>Em cima do papel filme, na direção vertical do recipiente, coloque a pedrinha.</li>
          <img src={Exp2Foto3} alt="Experimento - Pedra em cima dos recipientes" className="atv-img cap2-img"></img>
          <li>Acenda a luminária, ou a lanterna próximo ao recipiente- opcional.</li>
          <li>Deixe o experimento próximo à luminária (opcional) ou em um ambiente que receba a luz do sol.</li>
          <li>Após algumas horas ou alguns dias (dependendo da temperatura ambiente) a água irá evaporar bater no papel filme com a pedrinha acima que irá condensar a água e conduzir ao recipiente menor do centro. </li>
          <li>Anotem ou ilustrem  dos resultados observados.</li>
          <div>
            <img src={Exp2Foto5} alt="Fotografia da experiência - antes " className="atv-img cap2-img"></img>
            <img src={Exp2Foto4} alt="Fotografia da experiência - depois" className="atv-img cap2-img cap2-img-col"></img>
          </div>
        </ul>

        <div className="page-title page-subtitle page-subtitle-small">Conversando sobre o experimento</div>
        <div className="atv-text">Esse experimento pode ser comparado com quais elementos da natureza? Quantas horas ou dias levaram para que o potinho do centro do recipiente ficasse molhado? O que você acha que aconteceu com o sal e o corante no processo de evaporação?</div>
        <div className="atv-text">O recipiente maior contendo corante azul e sal representa os oceanos, o recipiente menor representa os continentes onde temos os rios, o papel filme com a pedra na sua superfície representa a atmosfera e o líquido que condensa no recipiente menor a chuva. A luz da lanterna ou da luminária, que é um elemento meramente ilustrativo, representa o sol, ela é dispensável nesse experimento, pois podemos colocar os recipientes em um ambiente com uma boa iluminação natural, mas ao mesmo tempo a luz artificial cria elementos didáticos que despertam a curiosidade das crianças. A visualização das primeiras gotas de água no recipiente menor pode levar horas ou mesmo dias, dependendo da temperatura ambiente. É importante destacar que a água que condensa é transparente mostrando que o corante não é evaporado junto com a água, explique que o sal também não evapora e a água do recipiente menor é doce; isso ocorre igualmente com a terra: a água do oceano evapora e condensa nos continente abastecendo os rios, levando água doce e influenciando no equilíbrio dos ecossistemas regionais (<b>EF05CI02</b>); um exemplo é a evaporação da água do oceano atlântico formando chuvas na floresta amazônica.</div>
      </div>

      <div>
        <div className='page-title page-subtitle'>CANTENCANTO <img src={steamArt} alt="ARTE" className="atv-ico"></img></div>
        <div className="atv-text">Vamos dançar ciranda? </div>
        <div className="atv-text">Mas antes de começarmos, vamos falar um pouco dessa manifestação popular e musical que envolve o canto e a dança e tem uma ligação com a beleza das águas do oceano. Essa relação se dá porque a ciranda chegou no Brasil pelas viagens das cirandeiras e cirandeiros através das caravelas vindas de Portugal no século XVIII. Elas fixaram suas âncoras no estado de Pernambuco, na Ilha de Itamaracá, e foram ensinadas pelas mulheres de pescadores que cantavam e dançavam esperando seus amados voltarem do mar. A ciranda avançou ainda mais as fronteiras  e hoje ela é dançada e cantada por todos, sendo uma divertida expressão artística que pode ser realizada nas escolas. Então,vamos cirandar ao som da música “Ciranda da Vida”?</div>

        <div className="music-title">CIRANDA DA VIDA</div>
        <div className="music-composition">Composição: Valter Silva</div>
        <div>
          <div>Roda a ciranda como roda a vida</div>
          <div>Bem vinda e linda assim como um rio</div>
          <div>Que vem surgindo numa calmaria </div>
          <div>Olho d´água espia </div>
          <div>A terra mãe que então sorriu</div>
          <div>Lá, bem no fundo onde a terra é livre </div>
          <div>A água brota e corre sem parar </div>
          <div>Encontra o sol, que pra um abraço quente</div>
          <div>Bichos , flores, vento e gente </div>
          <div>Vem pra te saudar </div>
          <div>Roda a ciranda, roda a vida, roda tudo </div>
          <div>Roda a terra, roda o mundo </div>
          <div>Ciclo d´água, quem te viu </div>
          <div>A água nasce, vira rio e vai pro mar</div>
          <div>Evapora, vira nuvem </div>
          <div>Pra depois voltar pro lar </div>
        </div>
        <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ciranda+da+Vida.mp3" target="_blank">Download da música</Button></div>
        <div><Button type="primary" icon={<DownloadOutlined />} className="download-button" href="https://encantodosrios-assets.s3.sa-east-1.amazonaws.com/Ciranda+da+vida.pdf" target="_blank">Download da letra e cifra</Button></div>
      </div>
    </div>
  );
}

export default AtvCap2;